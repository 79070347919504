import { useAuth } from "../../contexts/AuthContext";
import { Alert } from "react-bootstrap";
import {Container, Row, Col} from "react-bootstrap";

export default function ErrorMessage(){
    const {error, setError} = useAuth();

    return(
        <Container className="my-2">
            <Row className="justify-content-center">
                <Col lg={6} className="justify-content-center text-center">
                {error && <Alert dismissible  onClose={()=>{setError("")}} className="mx-3" variant="danger">
                    {error}
                </Alert>}
                </Col>
            </Row>
        </Container>
        
    )
}