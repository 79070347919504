/**
 * 
 * @param {number} value 
 * @param {string} currencyCode 
 * @param {string} locale 
 * @returns 
 */
export function formatCurrency(
  value,
  currencyCode,
  locale="en"
) {
  const majorUnits = displayPrice(value);
  try {
    // Note: if no `locale` is provided, the browser's default
    // locale will be used.
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    }).format(majorUnits);
  } catch (e) {
    // A fallback in case the NumberFormat fails for any reason
    return majorUnits;
  }
}

/**
 * Function to format price to two decimal point value
 * @param {number} priceWithTax 
 * @returns {number}
 */
export const displayPrice = (priceWithTax)=>{
    return (priceWithTax/100).toFixed(2);
}

/**
 * Function to get product details for product detail page
 * @param {String} slug 
 * @returns {JSON}
 */
export const fetchProductDetails = async(slug) => {
    const SHOP_API = process.env.REACT_APP_ECOM_GRAPHQL_SERVER
    const url = `${SHOP_API}/${slug}`;
    const query = {
      query: `
        query GetProductDetail($slug: String!) {
          product(slug: $slug) {
            id
            name
            description
            featuredAsset {
              id
              preview
            }
            assets {
              id
              preview
            }
            variants {
              id
              name
              sku
              stockLevel
              currencyCode
              price
              priceWithTax
              stockLevel
              featuredAsset {
                id
                preview
              }
              assets {
                id
                preview
              }
              customFields{
                description
                maxAdultCount
                maxChildCount
                maxAvailable
                productType
                maxExtra
                priceMAP
                priceAP
                costPerExtra
              }
            }
          }
        }
      `,
      variables: {
        slug,
      },
    };
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any necessary authentication headers here (if applicable)
      },
      body: JSON.stringify(query),
    };
  
    const response = await fetch(url, options);
  
    if (!response.ok) {
      throw new Error(`Error fetching product data: ${response.status}`);
    }
  
    const data = await response.json();
  
    // Handle potential errors in response data
    if (data.errors) {
      throw new Error(`Vendure GraphQL error: ${JSON.stringify(data.errors)}`);
    }
  
    return data.data.product; // Extract the product data from the response
  }

  export const getTaxTotalSum = (taxSummary) => {
    // Check if taxSummary is an array
    if (!Array.isArray(taxSummary)) {
      console.error('taxSummary must be an array');
      return
    }
  
    // Check if any elements exist in the array
    if (taxSummary.length === 0) {
      return 0; // Empty array, return 0 sum
    }
  
    // Reduce the array to sum taxTotal values
    return taxSummary.reduce((sum, current) => {
      // Check if current object has a 'taxTotal' property
      if (typeof current.taxTotal !== 'number') {
        throw new TypeError('taxTotal property missing or not a number');
      }
      return sum + current.taxTotal;
    }, 0);
  }

  export const getDiscountTotalSum = (discounts) => {
    // Check if discounts is an array
    if (!Array.isArray(discounts)) {
      console.error('discounts must be an array');
      return;
    }
  
    // Check if any elements exist in the array
    if (discounts.length === 0) {
      return 0; // Empty array, return 0 sum
    }
  
    // Reduce the array to sum discount values (amountWithTax is negative)
    return discounts.reduce((sum, current) => {
      // No type check needed for negative discounts
      return sum + current.amountWithTax;
    }, 0);
  };

  export const getNightCount = (bookingData)=>{
    try {
      // Parse the JSON string
      const parsedData = JSON.parse(bookingData);
      
      // Check if 'nightCount' property exists
      if (typeof parsedData.nightCount !== 'number') {
        throw new TypeError('nightCount property missing or not a number');
      }
      
      // Return nightCount if valid
      return parsedData.nightCount;
    } catch (error) {
      // Handle parsing errors or invalid data
      console.error('Error parsing booking data:', error);
      return null; // Or any default value you prefer
    }
  }
  
  
  