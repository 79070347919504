import './App.css';
import {
  PlasmicRootProvider,
  ComponentRenderData,
  PlasmicComponent,
} from '@plasmicapp/loader-react';
import { useQuery } from '@apollo/client';
import { PLASMIC } from './compoents/plasmic/plasmic-init';
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { CartContext } from './contexts/CartContext';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from './compoents/accounts/Register';
import Login from './compoents/accounts/Login';
import ErrorMessage from './compoents/layouts/ErrorMessage';
import Profile from './compoents/accounts/Profile';
import Header from './compoents/layouts/Header';
import PrivateRoutes from './utils/PrivateRoutes';
import Footer from './compoents/plasmic/Footer';
import Collections from './compoents/pages/Collections';
import ProductList from './compoents/pages/ProductList';
import ProductPage from './compoents/pages/ProductPage';
import CartPage from './compoents/pages/CartPage';
import CheckoutPage from './compoents/pages/CheckoutPage';
import CabBookingPage from './compoents/pages/CabBookingPage';
import { GET_ACTIVE_ORDER } from './utils/queries';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  //if there is an active order(cart) in the back end, update that in the cart context
  const { dispatch } = useContext(CartContext);
  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_ACTIVE_ORDER);
  useEffect(() => {
    if (!cartLoading && !cartError) {
      const fetchedData = cartData?.activeOrder;
      if (fetchedData) {
        dispatch({
          type: "UPDATE_CART",
          payload: fetchedData
        });
      }
    }
  }, [cartData, cartLoading, cartError, dispatch]); // Ensure this effect runs only once when the component mounts

  return (
    <HelmetProvider>
      <PlasmicRootProvider loader={PLASMIC}>
        <Router>
          <Header></Header>
          <ErrorMessage></ErrorMessage>
          <Routes>
            {/* Add the catch-all route for other paths */}
            <Route path="*" element={<CatchAllPage />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path='/holidays/stays' element={<Collections/>}/>
            <Route path="/holidays/stays/:slug" element={<ProductList/>} />
            <Route path="/stays/detail/:slug" element={<ProductPage/>} />
            <Route exact path ="/products" element={<Collections/>} />
            <Route path="/products/:slug" element={<ProductList/>} />
            <Route path="/products/detail/:slug" element={<ProductPage/>} />
            <Route path="/cart" element={<CartPage/>} />
            <Route path="/cab-booking" element={<CabBookingPage/>} />
            <Route exact path="/checkout" element={<CheckoutPage/>} />
            <Route exact path="/profile" element={
              <PrivateRoutes>
                <Profile />
              </PrivateRoutes>
            } />
            
          </Routes>
          <Footer></Footer>
        </Router>
      </PlasmicRootProvider>
    </HelmetProvider>
  );
}

export default App;
//plasmic pages
function CatchAllPage() {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null); // Use a simpler type here

  const location = useLocation(); // Access current location

  useEffect(() => {
    async function load() {
      const pageData = await PLASMIC.maybeFetchComponentData(location.pathname);
      setPageData(pageData);
      setLoading(false);
    }
    load();
  }, [location]); // Add location as a dependency

  if (loading) {
    return <div>Loading...</div>;
  }
  if (!pageData) {
    return <div>Not found</div>;
  }

  return (
    <>
    <Helmet>
      <title>{"Wayanadfinder |"+(location.pathname.replace(/\\|\//g,' '))}</title>
      <meta name='description' content="Looking for the best place to book your Wayanad trip?(or beyond Wayanad). Visit us for the best solution." />
    </Helmet>
      <PlasmicComponent component={location.pathname} />
    </>);
}