import { gql } from '@apollo/client';
import { ACTIVE_ORDER_FRAGMENT } from './fragments';

export const AUTHENTICATE = gql`
mutation Authenticate ($token: String!){
  authenticate(input:{
    firebase: {
      token: $token
    }
  }){
    __typename
    ... on ErrorResult{
      __typename
        errorCode 
        message
    }
    ... on CurrentUser{
      id
    }
  }
}
`

export const LOGOUT = gql`
mutation Logout{
  logout{
    	__typename
  }
}
`

export const GET_ACTIVE_ORDER = gql`
  query GetActiveOrder {
    activeOrder {
      ...ActiveOrder
    }
  }
  ${ACTIVE_ORDER_FRAGMENT}
`;

export const ADD_ITEM_TO_ORDER = gql`
mutation AddItemToOrder($variantId: ID!, $quantity: Int!, $bookingData: String) {
  addItemToOrder(productVariantId: $variantId, quantity: $quantity, customFields: {
    bookingData: $bookingData
  }) {
    __typename
    ...ActiveOrder
    ... on ErrorResult {
      errorCode
      message
    }
    ... on InsufficientStockError {
      quantityAvailable
    }
  }
}
${ACTIVE_ORDER_FRAGMENT}
`;

export const REMOVE_ITEM_FROM_ORDER = gql`
mutation RemoveItemFromOrder($orderLineId: ID!) {
    removeOrderLine(orderLineId: $orderLineId) {
      ...ActiveOrder
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${ACTIVE_ORDER_FRAGMENT}
`;

export const REMOVE_ALL_ORDER_LINES = gql`
mutation{
  removeAllOrderLines{
    __typename
    ...ActiveOrder
    ... on ErrorResult{
        errorCode
        message
    }
  }
}
fragment ActiveOrder on Order{
  id
  history{
    totalItems
  }
}
`;

export const ADJUST_ORDER_LINE = gql`
mutation AdjustOrderLine($orderLineId: ID!, $quantity: Int!) {
    adjustOrderLine(orderLineId: $orderLineId, quantity: $quantity) {
      ...ActiveOrder
      ... on ErrorResult {
          errorCode
          message
      }
    }
  }
  ${ACTIVE_ORDER_FRAGMENT}
`;

export const APPLY_COUPON_CODE = gql`
mutation ApplyCouponCode($couponCode: String!) {
    applyCouponCode(couponCode: $couponCode) {
      ...ActiveOrder
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
${ACTIVE_ORDER_FRAGMENT}
`;

export const REMOVE_COUPON_CODE = gql`
mutation RemoveCouponCode($couponCode: String!) {
  removeCouponCode(couponCode: $couponCode) {
    ...ActiveOrder
  }
}
${ACTIVE_ORDER_FRAGMENT}
`;

export const GET_CUSTOMER_ADDRESSES = gql`
query GetCustomerAddresses {
  activeCustomer {
    id
    addresses {
      id
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country {
        code
        name
      }
      phoneNumber
      defaultShippingAddress
      defaultBillingAddress
    }
  }
}
`;

export const SET_ORDER_SHIPPING_ADDRESS = gql`
mutation SetOrderShippingAddress($input: CreateAddressInput!) {

  setOrderShippingAddress(input: $input) {

    ...ActiveOrder

    ...on ErrorResult {

      errorCode

      message

    }

  }

}
${ACTIVE_ORDER_FRAGMENT}
`;

export const SET_ORDER_BILLING_ADDRESS = gql`
mutation SetOrderBillingAddress($input: CreateAddressInput!) {

  setOrderBillingAddress(input: $input) {

    ...ActiveOrder

    ...on ErrorResult {

      errorCode

      message

    }

  }

}
${ACTIVE_ORDER_FRAGMENT}
`;
export const GET_SHIPPING_METHODS = gql`
query GetShippingMethods {
  eligibleShippingMethods {
    id
    name
    price
    description
  }
}
`;

export const SET_SHIPPING_METHOD = gql`
mutation SetShippingMethod($id: [ID!]!) {
  setOrderShippingMethod(shippingMethodId: $id) {
      ...ActiveOrder
      ...on ErrorResult {
          errorCode
          message
      }
  }
}
${ACTIVE_ORDER_FRAGMENT}
`;

export const GET_TOP_COLLECTIONS = gql`
  query GetTopLevelCollections($topLevelOnly: Boolean!, $filter: CollectionFilterParameter) {
    collections(options: { topLevelOnly: $topLevelOnly, filter: $filter }) {
      items {
        id
        slug
        name
        featuredAsset {
          id
          preview
        }
      }
    }
  }
`;

export const GET_COLLECTION_PRODUCTS = gql`
    query GetCollectionProducts($slug: String!, $skip: Int, $take: Int) {
      search(
        input: {
          collectionSlug: $slug
          groupByProduct: true
          skip: $skip
          take: $take
        }
      ) {
        totalItems
        items {
          productName
          slug
          productAsset {
            id
            preview
          }
          priceWithTax {
            ... on SinglePrice {
              value
            }
            ... on PriceRange {
              min
              max
            }
          }
          currencyCode
        }
      }
    }
  `;

  export const SEARCH_PRODUCTS = gql`
  query SearchProducts($input: SearchInput!) {
    search(input: $input) {
      items {
        productId
        productName
        slug
        priceWithTax {
          ... on SinglePrice {
            value
          }
          ... on PriceRange {
            min
            max
          }
        }
        currencyCode
        productAsset {
          preview
        }
      }
    }
  }
`;

export const FETCH_PRODUCT_TYPE = gql`
  query FetchProductType($slug: String!) {
    product(slug: $slug) {
      slug
      variantList {
        items{
          id
          sku
          customFields{
            productType
          }
        }
      }
    }
  }
`;

export const FETCH_PRODUCT_TYPE_BYID = gql`
  query FetchProductDetails($id: ID!) {
    product(id: $id) {
      slug
      variantList {
        items {
          id
          sku
          customFields {
            productType
          }
        }
      }
    }
  }
`;

export const TRANSITION_ORDER_STATE = gql`
  mutation TransitionOrderState($state: String!) {
    transitionOrderToState(state: $state) {
      __typename
      ...ActiveOrder
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
    }
  }
  ${ACTIVE_ORDER_FRAGMENT}
`;

export const GET_ACTIVE_CUSTOMER = gql`
  query GetActiveCustomer($skip: Int, $take: Int) {
    activeCustomer {
      id
      title
      createdAt
      updatedAt
      firstName
      lastName
      phoneNumber
      emailAddress
      addresses {
        fullName
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        country {
          name
        }
        phoneNumber
        defaultBillingAddress
        defaultShippingAddress
      }
      orders(options: { skip: $skip, take: $take, 
        sort:{
          createdAt:DESC
        }
      }) {
        items {
          totalQuantity
          createdAt
          updatedAt
          code
          state
          active
          currencyCode
          totalWithTax
        }
      }
    }
  }
`;