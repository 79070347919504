import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

const RoomNightCount = ({ roomCount, startDate, endDate }) => {
  const [nightCount, setNightCount] = useState(null);

  useEffect(() => {
    const countNights = () => {
      // Create Date objects from the input dates.
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate the time difference in milliseconds.
      const diff = end.getTime() - start.getTime();

      // Convert the time difference to days.
      const days = diff / (1000 * 60 * 60 * 24);

      // Round the number of days to get the number of nights.
      const nights = Math.ceil(days);

      // Return the number of nights.
      return nights;
    };

    // Calculate and set the night count when startDate or endDate changes.
    if (startDate && endDate) {
      const nights = countNights();
      setNightCount(nights);
    }
  }, [startDate, endDate]); // Dependency array specifies when to re-run the effect

  return (
    <Row>
      <Col className='fs-4'>
        {`${roomCount} Room${roomCount !== 1 ? 's' : ''} for ${nightCount !== null ? nightCount : 'calculating'} night${
          nightCount !== 1 ? 's' : ''
        }`}
      </Col>
    </Row>
  );
};

export default RoomNightCount;
