import React from 'react';
import { useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { BsCart2, BsCartPlus, BsPersonCircle, BsSearch } from "react-icons/bs";
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import Logout from '../accounts/Logout';
import { useAuth } from "../../contexts/AuthContext";
import { CartContext } from '../../contexts/CartContext';
import SearchBar from '../SearchBar';
import Cart from '../Cart';

function Header() {
    const [show, setShow] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const { currentUser } = useAuth();
    const { cartState } = useContext(CartContext);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showCart, setShowCart] = useState(false);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
    const handleCartClose = () => setShowCart(false);
    const handleCartShow = () => setShowCart(true);
    const handleNavbarToggle = (expanded) => {
        setIsNavbarCollapsed(expanded);
    };

    return (
        <Container>
            <Navbar collapseOnSelect sticky="top" expand="lg" className="bg-body-tertiary" onToggle={(exp) => handleNavbarToggle(exp)}>
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img
                            alt=""
                            src={process.env.PUBLIC_URL + "/logo_transparent.png"}
                            width="77"
                            height="55"
                            className="d-inline-block align-top"
                        />{' '}
                        wayanadfinder
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        {(!isNavbarCollapsed && cartState?.totalQuantity > 0) &&
                            <>
                                <BsCartPlus size={22} />
                                <Badge className="bg-danger rounded-pill position-absolute top-5 start-95 translate-middle">
                                    {cartState?.totalQuantity > 0 && cartState.totalQuantity}
                                </Badge>
                            </>
                        }
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/" eventKey="home">Home</Nav.Link>
                            <NavDropdown title="Holidays" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/holidays/stays?location=wayanad">Wayanad</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/holidays/stays?location=beyond">
                                    Beyond
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Taxi/Cab" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/cab-booking?type=sightseeing">Sightseeing</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/cab-booking?type=transfer">
                                    Outstation transfer
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={Link} to="/products" eventKey="products">Products</Nav.Link>
                        </Nav>
                        <Row className='justify-content-center text-center text-secondary'>
                            <Col xs="auto">
                                <Nav.Link className="m-1" onClick={() => setIsSearchOpen(!isSearchOpen)}>
                                    <BsSearch size={18} />
                                </Nav.Link>
                            </Col>
                            <Col xs="auto">
                                <NavDropdown className="m-1" title={<span><BsPersonCircle size={18} /></span>} id="basic-nav-dropdown">
                                    {currentUser &&
                                        <>
                                            <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item onClick={handleShow}>Logout</NavDropdown.Item>
                                        </>
                                    }
                                    {!currentUser &&
                                        <>
                                            <NavDropdown.Item as={Link} to="/login">Login</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={Link} to="/register">Register</NavDropdown.Item>
                                        </>
                                    }
                                </NavDropdown>
                            </Col>
                            <Col xs="auto">
                                <Nav.Link href="#home" onClick={handleCartShow} className="m-1 position-relative">
                                    <BsCart2 size={22} />
                                    <Badge className="position-absolute top-0 start-100 translate-middle bg-danger rounded-pill">
                                        {cartState?.totalQuantity > 0 && cartState.totalQuantity}
                                    </Badge>
                                </Nav.Link>
                            </Col>
                        </Row>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Logout show={show} handleClose={handleClose} handleShow={handleShow}></Logout>
            {isSearchOpen && <SearchBar isSearchOpen={isSearchOpen} setSearchOpen={setIsSearchOpen}></SearchBar>}
            <Cart handleCartClose={handleCartClose} handleCartShow={handleCartShow} showCart={showCart}></Cart>
        </Container>
    );
}

export default Header;
