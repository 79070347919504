import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, InputGroup, Alert } from 'react-bootstrap';
import RoomNightCount from './RoomNightCount';
import { formatCurrency } from '../../utils/UtilFunctions';

import { useMutation } from '@apollo/client';
import { ADD_ITEM_TO_ORDER } from '../../utils/queries'; // Import the mutation

const StayBookingForm = ({ setOrderQuantity, variant }) => {
  const [adultsCount, setAdultsCount] = useState(2);
  const [childrenCount, setChildrenCount] = useState(0);
  const [totalRoomCount, setTotalRoomCount] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mealplan, setMealPlan] = useState("cp");
  const [dateAlert, setDateAlert] = useState(null);
  const [displayBookingPrice, setDisplayBookingPrice] = useState(0);

  const [addItemToOrder] = useMutation(ADD_ITEM_TO_ORDER);

  useEffect(() => {
    updateRoomCount();
  }, [adultsCount, childrenCount, variant]); // Trigger updateRoomCount on changes to adultsCount, childrenCount, and variant

  useEffect(()=>{
    setAdultsCount(2);
    setChildrenCount(0);
  },[variant])

  useEffect(()=>{
    calculateTotalPrice();
  },[totalRoomCount,startDate,endDate,mealplan,variant,adultsCount])

  const updateRoomCount = () => {
    let roomCount = 1;

    if (adultsCount > variant?.customFields.maxAdultCount) {
      const additionalAdults = adultsCount - (variant?.customFields.maxAdultCount + variant?.customFields.maxExtra);
      const additionalAdultRooms = Math.ceil(additionalAdults / (variant?.customFields.maxAdultCount + variant?.customFields.maxExtra));
      roomCount += additionalAdultRooms;
    }

    if (childrenCount > 0 && childrenCount > roomCount * variant?.customFields.maxChildCount) {
      const additionalChildren = childrenCount - (roomCount * variant?.customFields.maxChildCount);
      const additionalChildRooms = Math.ceil(additionalChildren / variant?.customFields.maxChildCount);
      roomCount += additionalChildRooms;
    }

    setTotalRoomCount(roomCount);
    setOrderQuantity(roomCount);
  };

  const handleAdultsDecrease = () => {
    if (adultsCount > 2) {
      setAdultsCount(adultsCount - 1);
    }
  };

  const handleAdultsIncrease = () => {
    setAdultsCount(adultsCount + 1);
  };

  const handleChildrenDecrease = () => {
    if (childrenCount > 0) {
      setChildrenCount(childrenCount - 1);
    }
  };

  const handleChildrenIncrease = () => {
    setChildrenCount(childrenCount + 1);
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();

    if (selectedDate < today) {
      setDateAlert('Please select a date starting from today or later.');
      setStartDate(null);
      return;
    }

    if (endDate && selectedDate >= new Date(endDate)) {
      setDateAlert('Please select a date earlier than the end date.');
      setStartDate(null);
      return;
    }

    setStartDate(e.target.value);
    setDateAlert('');
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();

    if (selectedDate < today) {
      setDateAlert('Please select a date starting from today or later.');
      setEndDate(null);
      return;
    }

    if (startDate && selectedDate <= new Date(startDate)) {
      setDateAlert('Please select a date later than the start date.');
      setEndDate(null);
      return;
    }

    setEndDate(e.target.value);
    setDateAlert('');
  };

  const handleAddRoom = () => {
    setTotalRoomCount(totalRoomCount + 1);
    setOrderQuantity(totalRoomCount + 1);
  };

  const calculateExtraAdults = () => {
    const maxAdultCount = variant?.customFields.maxAdultCount || 1; // Default to 1 if maxAdultCount is not available
    const maxExtra = variant?.customFields.maxExtra || 0; // Default to 0 if maxExtra is not available
  
    const totalMaxAdults = totalRoomCount * maxAdultCount;
  
    if (adultsCount > totalMaxAdults) {
      // Calculate the total number of extra adults
      const extraAdults = adultsCount - totalMaxAdults;
  
      // Cap the extra adults to the maximum allowed per room
      const cappedExtraAdults = Math.min(extraAdults, totalRoomCount * maxExtra);
  
      return cappedExtraAdults;
    } else {
      return 0; // No extra adults if within the maximum allowed per room
    }
  };
  

  const countNights = () => {
    if (startDate && endDate) {
      // Create Date objects from the input dates.
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      // Calculate the time difference in milliseconds.
      const diff = end.getTime() - start.getTime();
  
      // Convert the time difference to days.
      const days = diff / (1000 * 60 * 60 * 24);
  
      // Round the number of days to get the number of nights.
      const nights = Math.ceil(days);
  
      // Return the number of nights.
      return nights;
    } else {
      // Return default of 1 night if dates are missing or invalid.
      return 1;
    }
  };
  
  const calculateTotalPrice = () => {
    if (startDate && endDate) {
      let currentPrice = 0;
  
      // Determine the current price based on the selected meal plan
      switch (mealplan) {
        case 'ap':
          currentPrice = (variant?.customFields.priceAP || 0) * 100; // Ensure to handle cases where priceAP is undefined
          break;
        case 'map':
          currentPrice = (variant?.customFields.priceMAP || 0) * 100; // Ensure to handle cases where priceMAP is undefined
          break;
        default:
          currentPrice = (variant?.price || 0); // Ensure to handle cases where variant.price is undefined
      }
  
      // Calculate the total number of nights based on the selected dates
      const nights = countNights();
  
      // Calculate the total price based on room count, price per room, number of nights, and extra adults
      const totalPrice =
        totalRoomCount * currentPrice * nights +
        (variant?.customFields.costPerExtra || 0) * calculateExtraAdults() * 100;
  
      // Set the calculated total price in the component state
      setDisplayBookingPrice(totalPrice);
    } else {
      // Handle case where dates are missing or invalid
      setDisplayBookingPrice(0); // Set a default price or clear the display price
    }
  };

  const handleAddItemToOrder = async () => {
    const bookingData = {
      startDate,
      endDate,
      nightCount: countNights(),
      adultCount: adultsCount,
      extraCount: calculateExtraAdults(),
      kidsCount: childrenCount,
      mealPlan: mealplan
    };

    try {
      await addItemToOrder({
        variables: {
          variantId: variant.id,
          quantity: totalRoomCount,
          bookingData: JSON.stringify(bookingData)
        }
      });

      // Handle success
      console.log('Item added to order successfully!');
    } catch (error) {
      // Handle error
      console.error('Error adding item to order:', error);
    }
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col xs="auto">
            {dateAlert && 
              <Alert variant='danger'>
                {dateAlert}
              </Alert>
            }
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="my-2">
            <Form.Label>Check-in</Form.Label>
            <Form.Control type="date" onChange={handleStartDateChange}/>
          </Col>
          <Col xs="auto" className="my-2">
            <Form.Label>Check-out</Form.Label>
            <Form.Control type="date" onChange={handleEndDateChange}/>
          </Col>
        </Row>
        <Row>
          <Col sm="3" xs="6" className="my-2">
            <Form.Label>Adults</Form.Label>
            <InputGroup size="sm">
              <Button variant="outline-secondary" onClick={handleAdultsDecrease}>
                -
              </Button>
              <Form.Control type="text" value={adultsCount} readOnly />
              <Button disabled={variant?.customFields.maxAvailable <= totalRoomCount} variant="outline-secondary" onClick={handleAdultsIncrease}>
                +
              </Button>
            </InputGroup>
          </Col>
          <Col sm="3" xs="6" className="my-2">
            <Form.Label>Kids (up to 5 YO)</Form.Label>
            <InputGroup size="sm">
              <Button variant="outline-secondary" onClick={handleChildrenDecrease}>
                -
              </Button>
              <Form.Control type="text" value={childrenCount} readOnly />
              <Button disabled={(variant?.customFields.maxChildCount*totalRoomCount) <= childrenCount} variant="outline-secondary" onClick={handleChildrenIncrease}>
                +
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className='my-2'>
            <Form.Select value={mealplan} onChange={(e) => setMealPlan(e.target.value)} aria-label="Meal plan select">
              <option>Select Meal plan</option>
              <option value="cp">CP - Includes Breakfast</option>
              {variant?.customFields.priceMAP && <option value="map">MAP - Breakfast + 1 meal</option>}
              {variant?.customFields.priceAP && <option value="ap">AP - All 3 meals</option>}
            </Form.Select>
          </Col>
        </Row>
        <Row className='my-2'>
          <Col>
            <RoomNightCount roomCount={totalRoomCount} startDate={startDate} endDate={endDate} />
            <p className="text-muted fw-lighter">Automated room count considers the maximum number of adults sharing a room.
            <Button variant='link' onClick={handleAddRoom} disabled={variant?.customFields.maxAvailable <= totalRoomCount}>Add Room</Button>
            <span> or adjust adult count above.</span></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='fw-lighter fs-4'>{formatCurrency(displayBookingPrice, variant?.currencyCode)}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={handleAddItemToOrder}>Add to trip</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StayBookingForm;
