// SelectAddress.jsx

import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const SelectAddress = ({ addresses, onSelect, type }) => {
  const handleSelect = (e) => {
    onSelect(type, e.target.value);
  };

  return (
    <Row className='my-2'>
      <Col>
        <Form>
          <Form.Group as={Col} controlId="formGridSelectAddress">
            <Form.Label>Select Address</Form.Label>
            <Form.Select onChange={handleSelect}>
              {addresses.length === 0
                ? <option>No saved address</option>
                :
                <>
                <option>Select</option>
                {addresses.map((address) => (
                    <option key={address.id} value={address.id}>
                      {address.fullName}, {address.postalCode}
                    </option>
                  ))}
                </>
                }
            </Form.Select>
            <Form.Text>Or enter address below</Form.Text>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};

export default SelectAddress;
