import React from 'react'
import { Row, Col } from 'react-bootstrap'

function ProductTitle({title}) {
  return (
    <Row>
        <Col>
            <h1>{title}</h1>
        </Col>
    </Row>
  )
}

export default ProductTitle