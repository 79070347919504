import React, { useContext } from 'react';
import { CartContext } from '../contexts/CartContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { Image } from 'react-bootstrap';
import { formatCurrency, getDiscountTotalSum, getTaxTotalSum, getNightCount } from '../utils/UtilFunctions';
import { useNavigate } from 'react-router-dom';

function Cart({ handleCartClose, showCart }) {
  const { cartState } = useContext(CartContext);
  const navigate = useNavigate();

  const handleNaigateToCart = ()=>
  {
      handleCartClose();
      navigate("/cart");
  }

  const handleNavigateToCheckout = ()=>{
    if(cartState?.lines.length>0){
      console.log(" cart - handleNavigateToCheckout");
      handleCartClose();
      navigate('/checkout');
    }
  }

  return (
    <>
      <Modal size="lg" show={showCart} onHide={handleCartClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cartState?.lines.length > 0 && (
            <>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Price with Tax</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cartState?.lines.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className= ''
                    >
                      <td><span className='me-1'><Image className='cartImage' src={item?.featuredAsset?.preview+"?preset=tiny"} thumbnail/></span><span>{item.productVariant.name}</span></td>
                      <td>
                      {item.customFields.bookingData?(`${item.quantity} x room for ${getNightCount(item.customFields.bookingData)} x nights`):item.quantity}
                      </td>
                      <td>{formatCurrency(item.unitPriceWithTax, cartState?.currencyCode)}</td>
                      <td>{formatCurrency(item.linePriceWithTax, cartState?.currencyCode)}</td>
                    </tr>
                  );
                })}
                {cartState?.discounts.length> 0 && <tr>
                <td></td>
                <td></td>
                <td><h6>Discounts</h6></td>
                <td><><span className='me-2'>{cartState.currencyCode}</span>{formatCurrency(getDiscountTotalSum(cartState?.discounts), cartState?.currencyCode)}</></td>
                </tr>}
                <tr>
                <td></td>
                <td></td>
                <td><h6>Tax included</h6></td>
                <td><><span className='me-2'>{cartState.currencyCode}</span>{formatCurrency(getTaxTotalSum(cartState?.taxSummary), cartState?.currencyCode)}</></td>
                </tr>
                <tr>
                <td></td>
                <td></td>
                <td><h5>Subtotal</h5></td>
                <td><h5><span className='me-2'>{cartState.currencyCode}</span>{formatCurrency(cartState?.totalWithTax, cartState?.currencyCode)}</h5></td>
                </tr>
              </tbody>
            </Table>
            
            </>
          )}
          {cartState?.lines.length === 0 && (
            <p className="ms-2 text-danger">Your Cart is Empty</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>handleNaigateToCart()} disabled={cartState.state !== "AddingItems"}>
            Edit Cart
          </Button>
          <Button variant="primary" onClick={()=>handleNavigateToCheckout()}>Checkout</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Cart;
