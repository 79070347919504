import React from 'react'
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

function ProductImages({assets}) {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
      };
    
      return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {
                assets.map((asset, idx)=>{
                    return (<Carousel.Item key={idx}>
                        <Image src={asset.preview+"?preset=large"} fluid />
                    </Carousel.Item>)
                })
            }
        </Carousel>
      );
}

export default ProductImages