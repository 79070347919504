// AddressForm.jsx

import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Country, State } from 'country-state-city';

const AddressForm = ({ formData, onSubmit, type }) => {
  const allCountries = Country.getAllCountries();
  const [selectedCountry, setSelectedCountry] = useState(formData?.countryCode || "");
  const [selectedStates, setSelectedStates] = useState([]);
  const [state, setState] = useState(formData?.province || "");
  const [phoneCode, setPhoneCode] = useState(formData?.phoneCode || "");
  const [phoneNumber, setPhoneNumber] = useState(formData?.phoneNumber || "");
  const [isInvalid, setIsInvalid] = useState(false); // To track form validation
  const [defaultShippingAddress, setDefaultShippingAddress] = useState(true);
  const [defaultBillingAddress, setDefaultBillingAddress] = useState(false);
  const [fullName, setFullName] = useState(formData?.fullName || "");
  const [company, setCompany] = useState(formData?.company || "");
  const [streetLine1, setStreetLine1] = useState(formData?.streetLine1 || "");
  const [streetLine2, setStreetLine2] = useState(formData?.streetLine2 || "");
  const [city, setCity] = useState(formData?.city || "");
  const [postalCode, setPostalCode] = useState(formData?.postalCode || "");

  const formRef = useRef(null);

  useEffect(() => {
    // Fetch states based on selected country
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setSelectedStates(states);
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Update form data when formData prop changes
    if (formData) {
      setSelectedCountry(formData.countryCode || "");
      setSelectedStates(State.getStatesOfCountry(formData.countryCode) || []);
      setState(formData.province || "");
      setPhoneCode(formData.phoneCode || "");
      setPhoneNumber(formData.phoneNumber || "");
      setFullName(formData.fullName || "");
      setCompany(formData.company || "");
      setStreetLine1(formData.streetLine1 || "");
      setStreetLine2(formData.streetLine2 || "");
      setCity(formData.city || "");
      setPostalCode(formData.postalCode || "");
    }
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields (except Address Line 2)
    if (!validateForm()) {
      setIsInvalid(true);
      return;
    }

    setIsInvalid(false); // Reset validation state in case of successful submission

    // Prepare address data for mutation
    const addressData = {
      fullName: fullName,
      company: company, // Optional company field
      streetLine1: streetLine1,
      streetLine2: streetLine2, // Allow optional Address Line 2
      city: city,
      province: state,
      postalCode: postalCode,
      countryCode: selectedCountry,
      phoneNumber: phoneCode + phoneNumber, // Combine phone code and number
      defaultBillingAddress: defaultBillingAddress,
      defaultShippingAddress: defaultShippingAddress
    };

    onSubmit(addressData, type); // Call the original onSubmit function
  };

  const handleDefaultAddressCheck = () => {
    if (type === "billing") {
      setDefaultBillingAddress(true);
    } else if (type === "shipping") {
      setDefaultShippingAddress(true);
    }
  }

  const validateForm = () => {
    const form = formRef.current; // Access the form element using the ref
    if (!form) return; // Check if the form exists before proceeding
    const inputs = form.querySelectorAll('input[required]');

    let isValid = true;
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (!input.value.trim()) {
        input.classList.add('variant-danger'); // Add error class
        isValid = false;
      } else {
        input.classList.remove('variant-danger'); // Remove error class on valid input
      }
    }

    return isValid;
  };

  const handleStateSelect = (e) => {
    setState(e.target.value);
  }

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    const countryData = Country.getCountryByCode(countryCode);
    setPhoneCode(countryData.phonecode);
    const states = State.getStatesOfCountry(countryCode);
    setSelectedStates(states);
    setState(""); // Reset state when country changes
  };

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      {/* Form fields for address details */}
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            placeholder="First Name"
            value={fullName.split(" ")[0] || ""}
            onChange={(e) => setFullName(e.target.value + " " + fullName.split(" ")[1])}
            required
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            placeholder="Last Name"
            value={fullName.split(" ")[1] || ""}
            onChange={(e) => setFullName(fullName.split(" ")[0] + " " + e.target.value)}
            required
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Address line 1</Form.Label>
        <Form.Control
          placeholder="1234 Main St"
          value={streetLine1}
          onChange={(e) => setStreetLine1(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>Address line 2</Form.Label>
        <Form.Control
          placeholder="Apartment, studio, or floor"
          value={streetLine2}
          onChange={(e) => setStreetLine2(e.target.value)}
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formGridCity">
        <Form.Label>City</Form.Label>
        <Form.Control
          value={city}
          onChange={(e) => setCity(e.target.value)}
          required
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCountry">
          <Form.Label>Country</Form.Label>
          <Form.Select
            value={selectedCountry}
            onChange={(e) => handleCountryChange(e)}
            required
          >
            {allCountries.map((country, index) => (
              <option key={index} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>State</Form.Label>
          <Form.Select
            value={state}
            onChange={(e) => handleStateSelect(e)}
            required
          >
            <option>Choose...</option>
            {selectedStates.map((state, index) => (
              <option key={index} value={state.name}>
                {state.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            required
          />
        </Form.Group>
      </Row>

      {/* Phone Code and Number Fields */}
      <Row className="mb-3">
        <Form.Group as={Col} xs={4} controlId="formGridPhoneCode">
          <Form.Label>Phone Code</Form.Label>
          <Form.Control disabled value={phoneCode} />
        </Form.Group>

        <Form.Group as={Col} xs={8} controlId="formGridPhoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" id="formGridCheckbox">
        <Form.Check
          type="checkbox"
          label={`Make this my default ${type} address`}
          checked={type === "billing" ? defaultBillingAddress : defaultShippingAddress}
          onChange={() => handleDefaultAddressCheck()}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default AddressForm;
