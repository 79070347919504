import React, { useState } from 'react';
import { Row, Col, Form, Button, Offcanvas, Spinner } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PRODUCTS } from '../utils/queries';
import SearchResults from './elements/SearchResults';

function SearchBar({ isSearchOpen, setSearchOpen }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [executeSearch, { loading }] = useLazyQuery(SEARCH_PRODUCTS, {
    onCompleted: (data) => {
      setSearchResults(data?.search?.items || []);
      setShowResults(data?.search?.items.length > 0);
    },
    onError: (error) => {
      console.error('Error fetching search results:', error);
      setShowResults(false);
    }
  });

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      executeSearch({ variables: { input: { term: searchTerm, skip: 0, take: 10, groupByProduct: true } } });
    }
  };

  const closeOffcanvas = () => setShowResults(false);

  return (
    <>
      {isSearchOpen && (
        <Row className='justify-content-center'>
          <Col className="m-2" lg={6} sm={8}>
            <div className="search-bar open">
              <Form className="d-flex" onSubmit={handleSearch}>
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button variant="outline-success" type="submit">Search</Button>
              </Form>
            </div>
          </Col>
        </Row>
      )}
      <Offcanvas show={showResults} onHide={closeOffcanvas} placement="bottom" className="custom-offcanvas h-auto">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Search Results</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {loading ? <Spinner animation="border" /> : <SearchResults results={searchResults} closeOffcanvas={closeOffcanvas} />}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SearchBar;
