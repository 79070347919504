import React from 'react'
import { Row,Col } from 'react-bootstrap'
import { formatCurrency } from '../utils/UtilFunctions'

function ProductAttributes({variant}) {
  console.log("variant",variant);
  return (
    <Row className='my-3'>
        <Col>
        <Row>
            <Col>
                <span className='text-muted'>SKU: {variant?.sku}</span>
            </Col>
        </Row>
        <Row>
            <Col>
            <span className='text-muted'>{variant?.currencyCode}</span>
            <span className='mx-1'>{formatCurrency(variant?.price,variant?.currencyCode)} </span>
            <span className='mx-1 text-muted'>{variant?.customFields?.productType === "Accommodation"?"per room per night":"per piece"}</span>
            <span className='mx-1 text-muted'>| GST extra</span>
            </Col>
        </Row>
        </Col>
    </Row>
  )
}

export default ProductAttributes