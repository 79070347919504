import React, { createContext, useReducer } from "react";
// Define the initial cart state
const initialState = {
  lines:[],
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return addToCart(state, action.payload.selectedVariant, action.payload.quantity);
    case "INCREASE_QUANTITY":
      return increaseItemQuantity(state, action.payload.itemId);
    case "DECREASE_QUANTITY":
      return decreaseItemQuantity(state, action.payload.itemId);
    case "REMOVE_FROM_CART":
      return removeItemFromCart(state, action.payload.itemId);
    case "UPDATE_QUANTITY":
      return updateItemQuantity(state, action.payload.itemSku, action.payload.quantity);
    case "CLEAR_CART":
      return initialState;
    case 'UPDATE_CART':
      return { ...state, ...action.payload }; // Update entire cart state based on server response
    default:
      return state;
  }
};

// Function to add item to cart
export const addToCart = (state, item, quantity) => {
  const existingItemIndex = state.cartItems.findIndex((cartItem) => cartItem.sku === item.sku);
  const cartItems = existingItemIndex !== -1
    ? state.cartItems.map((cartItem, index) =>
        index === existingItemIndex
          ? { ...cartItem, quantity: cartItem.quantity + quantity }
          : cartItem
      )
    : [...state.cartItems, { ...item, quantity }];

  const totalItems = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
  const totalPrice = cartItems.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0);

  return { ...state, cartItems, totalItems, totalPrice };
};

// Function to increase item quantity
export const increaseItemQuantity = (state, itemSku) => {
  const cartItems = state.cartItems.map((item) => {
    if (item.sku === itemSku) {
      return { ...item, quantity: item.quantity + 1 };
    }
    return item;
  });

  const totalItems = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
  const totalPrice = cartItems.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0);

  return { ...state, cartItems, totalItems, totalPrice };
};

// Function to decrease item quantity
export const decreaseItemQuantity = (state, itemSku) => {
  const cartItems = state.cartItems.map((item) => {
    if (item.sku === itemSku && item.quantity > 1) {
      return { ...item, quantity: item.quantity - 1 };
    }
    return item;
  });

  const totalItems = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
  const totalPrice = cartItems.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0);

  return { ...state, cartItems, totalItems, totalPrice };
};

// Function to remove item from cart
export const removeItemFromCart = (state, itemSku) => {
  const cartItems = state.cartItems.filter((item) => item.sku !== itemSku);

  const totalItems = cartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
  const totalPrice = cartItems.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0);

  return { ...state, cartItems, totalItems, totalPrice };
};

// Function to update item quantity
const updateItemQuantity = (state, itemSku, newQuantity) => {
  const updatedCartItems = state.cartItems.map((item) => {
    if (item.sku === itemSku) {
      return { ...item, quantity: newQuantity };
    }
    return item;
  });

  const totalItems = updatedCartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);
  const totalPrice = updatedCartItems.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0);

  return { ...state, cartItems: updatedCartItems, totalItems, totalPrice };
};

// Create the CartContext
export const CartContext = createContext(initialState);

// Context provider component
export const CartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(reducer, initialState);

  // ... functions to dispatch actions (similar to previous implementation)

  return (
    <CartContext.Provider value={{ cartState, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};