import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { Container,Row,Col,Button,Form,Alert } from 'react-bootstrap';
import Seo from '../layouts/Seo';

const CabBookingPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const [submitForm, setSubmitForm] = useState(false)

    const handleSubmit = (e)=>{
        e.preventDefault();
        setSubmitForm(true);
    }
    return (
        <>
        <Seo title ="Wayanadfinder | Cabs"
        description="Book your outstation transfer, airport transfer or sightseeing cabs from us. Professionalism, safety and hassle free experience guaranteed"
        type="webapp"
        />
        <Container>
            <Alert variant='danger'>Connection to cabs not live. We are still building this. Meanwhile our team will help you through other channels</Alert>
            {!submitForm?<Row>
                <Col>
                    <h3>Looking for a <span>{type}</span> cab on your holiday?</h3>
                    <p>Select appropriate options from the below form and our team will reach you with solutions soon.</p>
                    {/* Form  on logic based on the type query parameter */}
                    <Form onSubmit={(e)=>handleSubmit(e)}>
                        <Form.Group as={Row} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>                    

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCityPick">
                            <Form.Label>Pick Up Location</Form.Label>
                            <Form.Control />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridStatePick">
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue="Choose...">
                                <option>Choose...</option>
                                <option>Kerala</option>
                                <option>Karnataka</option>
                            </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCityDrop">
                            <Form.Label>Drop Location</Form.Label>
                            <Form.Control />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridStateDrop">
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue="Choose...">
                                <option>Choose...</option>
                                <option>Kerala</option>
                                <option>Karnataka</option>
                            </Form.Select>
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" id="formGridCheckbox">
                            <Form.Check type="checkbox" label="AC" />
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextCabType">
                            <Form.Label column sm="2">
                            Requirement Type
                            </Form.Label>
                            <Col sm="8">
                            <Form.Control plaintext readOnly defaultValue={type} />
                            </Col>
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled>
                            Submit Enquiry
                        </Button>
                    </Form>
                </Col>
            </Row>
            :<Row>
                <Alert onClose={() => setSubmitForm(false)} dismissible>Thank you for reaching out. We will get back to your soon.</Alert>
            </Row>}
        </Container>

        </>     
      
    );
}

export default CabBookingPage