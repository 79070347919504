import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CartContext } from '../../contexts/CartContext';
import { useMutation } from '@apollo/client';
import { REMOVE_ALL_ORDER_LINES } from '../../utils/queries';

const ClearCartWarning = ({ showModal, closeModal }) => {
  const { dispatch } = useContext(CartContext);

  const [removeAllOrderLines] = useMutation(REMOVE_ALL_ORDER_LINES, {
    onCompleted: () => {
      dispatch({ type: 'CLEAR_CART' });
      closeModal();
    },
    onError: (error) => {
      console.error('Error clearing cart:', error);
      closeModal();
    },
  });

  const handleClearCart = () => {
    removeAllOrderLines();
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Clear Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to clear your cart?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="danger" onClick={handleClearCart}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClearCartWarning;
