import React, { useState, useContext } from 'react';
import { CartContext } from '../contexts/CartContext';
import { Form, FormControl, Button } from 'react-bootstrap';
import { BsCheckLg } from 'react-icons/bs';
import { Row, Col } from 'react-bootstrap';

function QuantityEditInput({ item, handleSetQuantity }) {
  const [editingQuantity, setEditingQuantity] = useState(item.quantity);
  const {dispatch } = useContext(CartContext);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    newQuantity && setEditingQuantity(newQuantity); // Update local state
  };

  const handleQtySubmit = (e) => {
    e.preventDefault();
    handleSetQuantity(editingQuantity);
    // if (editingQuantity && editingQuantity >= 1) {
    //   // Dispatch action to update quantity in cart context
    //   dispatch({
    //     type: 'UPDATE_QUANTITY',
    //     payload: { itemSku: item.sku, quantity: editingQuantity },
    //   });
    //   // Update selectedRow state (optional, depending on your implementation)
    //   // You might want to update the selectedRow state in the Cart component
    //   // to reflect the updated quantity after a successful dispatch.
    //   clearSelectedRow();
    // }
  };

  return (
    <Form inline onSubmit={handleQtySubmit}>
      <Row>
        <Col xs="4">
          <FormControl
            type="number"
            value={editingQuantity}
            onChange={handleQuantityChange}
            className="quantity-input"
            size="sm"
            min="1"
            readOnly={false}
          />
        </Col>
        <Col xs="auto">
          <Button variant="outline-primary" size="sm" type="submit">
            <BsCheckLg />
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default QuantityEditInput;
