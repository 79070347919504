// ProductVariantList
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function ProductVariantList({ variants, onVariantSelected, selectedVariant }) {
  const [internalSelectedVariant, setInternalSelectedVariant] = useState(null);

  useEffect(() => {
    if (variants.length === 1) {
      setInternalSelectedVariant(variants[0]);
      onVariantSelected(variants[0]);
    }
  }, [variants, onVariantSelected]);

  useEffect(() => {
    if (selectedVariant) {
      setInternalSelectedVariant(selectedVariant);
    }
  }, [selectedVariant]);

  const handleVariantChange = (event) => {
    const variantSelected = variants.find((variant) => variant.name === event.target.value);
    setInternalSelectedVariant(variantSelected);
    onVariantSelected(variantSelected);
  };

  return (
    <Row>
      <Col xs="auto">
        <Form.Select
          aria-label="Variant select"
          value={internalSelectedVariant?.name || ""}
          onChange={handleVariantChange}
        >
          <option disabled={variants.length === 1}>Select Options</option>
          {variants.map((variant, idx) => (
            <option key={idx} value={variant.name}>
              {variant.name}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Row>
  );
}

export default ProductVariantList;
