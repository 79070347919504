import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { CartContext } from '../../contexts/CartContext';
import { useMutation } from '@apollo/client';
import { ADJUST_ORDER_LINE } from '../../utils/queries';
import QuantityEditInput from '../QuantityEditInput';
import { displayPrice } from '../../utils/UtilFunctions';

const EditQuantityModal = ({showModal, handleRowSelect, closeModal, selectedRow}) => {
    const { dispatch } = useContext(CartContext);

    const [adjustOrderLine] = useMutation(ADJUST_ORDER_LINE, {
    onCompleted: (data) => {
        const fetchedData = data?.adjustOrderLine 
        dispatch({ type: 'UPDATE_CART',payload: fetchedData });
        closeModal();
    },
    onError: (error) => {
        console.error('Error updating cart:', error);
        closeModal();
    },
    });

    const handleUpdateCart = async () => {
        if(selectedRow){
            try{
                await adjustOrderLine({
                    variables:{orderLineId: selectedRow.id, quantity:selectedRow.quantity}
                })
        
            }
            catch(error){
                console.error('Error removing item from order:', error.message);
                // Handle error here, e.g., display error message to user
            }
            finally{
                handleRowSelect(selectedRow);
            }
        }
      };

      const handleSetQuantity = (newQuantity)=>{
        selectedRow = {...selectedRow, quantity:newQuantity}
      }

    return (
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Clear Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>1</td>
                        <td><QuantityEditInput item={selectedRow} handleSetQuantity={handleSetQuantity}/></td>
                        <td>{displayPrice(selectedRow.unitPriceWithTax)}</td>
                        <td>{displayPrice(selectedRow.linePriceWithTax)}</td>
                        </tr>
                    </tbody>
                </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleUpdateCart}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      );
}

export default EditQuantityModal;
