import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FETCH_PRODUCT_TYPE } from '../../utils/queries'; 

function SearchResults({ results, closeOffcanvas }) {
  const navigate = useNavigate();
  const [fetchProductType] = useLazyQuery(FETCH_PRODUCT_TYPE, {
    onCompleted: (data) => {
      const productType = data.product.variantList.items[0]?.customFields.productType;
      const path = productType === 'Accommodation'
        ? `/stays/detail/${data.product.slug}`
        : `/products/detail/${data.product.slug}`;
      navigate(path);
      closeOffcanvas(); // Close Offcanvas on navigation
    },
    onError: (error) => {
      console.error('Error fetching product type:', error);
    }
  });

  const handleCardClick = (slug) => {
    fetchProductType({ variables: { slug } });
  };

  return (
    <Row className="search-results">
      {results.map((product) => (
        <Col key={product.slug} md={2} xs={6} className="mb-3">
          <Card onClick={() => handleCardClick(product.slug)} style={{ cursor: 'pointer' }}>
            <Card.Img variant="top" src={product.productAsset?.preview} />
            <Card.Body>
              <Card.Title>{product.productName}</Card.Title>
              <Card.Text>
                {product.priceWithTax.value
                  ? `${product.currencyCode} ${product.priceWithTax.value / 100}`
                  : `${product.currencyCode} ${product.priceWithTax.min / 100} - ${product.priceWithTax.max / 100}`}
              </Card.Text>
              <Button variant="primary">View Product</Button>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default SearchResults;
