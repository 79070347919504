// PaymentSection.js
import React, { useContext, useState, useEffect } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Button, Alert, Spinner, Modal, Row,Col } from 'react-bootstrap';
import { CartContext } from '../../contexts/CartContext';
import { useNavigate } from 'react-router-dom'; // Ensure you have react-router-dom v6
import { ACTIVE_ORDER_FRAGMENT } from '../../utils/fragments';
import { GET_ACTIVE_ORDER } from '../../utils/queries'; // Import GET_ACTIVE_ORDER
import { formatCurrency } from '../../utils/UtilFunctions';
import Image from 'react-bootstrap/Image';
import { BsCheckCircleFill } from "react-icons/bs";

const CREATE_RAZORPAY_ORDER = gql`
  mutation CreateRazorpayOrder($amount: Int!, $currency: String!, $receipt: String!, $orderId: ID!) {
    createRazorpayOrder(amount: $amount, currency: $currency, receipt: $receipt, orderId: $orderId) {
      orderId
      error
      key_id
    }
  }
`;

const TRANSITION_ORDER_TO_STATE = gql`
  mutation TransitionOrderToState($state: String!) {
    transitionOrderToState(state: $state) {
      __typename
      ...ActiveOrder
      ... on OrderStateTransitionError {
        errorCode
        message
        transitionError
        fromState
        toState
      }
    }
  }
  ${ACTIVE_ORDER_FRAGMENT}
`;

const ADD_PAYMENT_TO_ORDER = gql`
  mutation AddPaymentToOrder($input: PaymentInput!) {
    addPaymentToOrder(input: $input) {
      __typename
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
`;

const GET_ORDER_BY_CODE = gql`
  query GetOrderByCode($code: String!) {
    orderByCode(code: $code) {
      ...ActiveOrder
    }
  }
  ${ACTIVE_ORDER_FRAGMENT}
`;

const PaymentSection = ({availabilityNeeded}) => {
  const { cartState } = useContext(CartContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalOrder, setModalOrder] = useState(null);
  const [showError, setShowError] = useState(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState(cartState.customFields.rzpayOrderId);

  const { loading: getOrderLoading, data: orderData, refetch } = useQuery(GET_ORDER_BY_CODE, {
    variables: {
      code: cartState.code,
    },
    skip: !showModal, // Refetch only when modal is to be shown
  });

  const { refetch: refetchActiveOrder } = useQuery(GET_ACTIVE_ORDER, {
    skip: true, // Skip initial execution
  });

  useEffect(() => {
    if (orderData && orderData.orderByCode) {
      setModalOrder(orderData.orderByCode);
    }
  }, [orderData]);

  const [transitionOrderToState] = useMutation(TRANSITION_ORDER_TO_STATE, {
    onError: (error) => {
      console.error('Transition order error:', error);
      setShowError(true);
    },
  });

  const [createRazorpayOrder, { loading: razorpayLoading }] = useMutation(CREATE_RAZORPAY_ORDER, {
    variables: {
      amount: cartState.totalWithTax,
      currency: cartState.currencyCode,
      receipt: cartState.code,
      orderId: cartState.id, // Ensure this is the correct order ID
    },
    onError: (error) => {
      console.error('Create Razorpay order error:', error);
      setShowError(true);
    },
    onCompleted: async (data) => {
      const { orderId, key_id } = data.createRazorpayOrder;
      setRazorpayOrderId(orderId);

      if (cartState.state !== 'ArrangingPayment') {
        const transitionResponse = await transitionOrderToState({
          variables: {
            state: 'ArrangingPayment',
          },
        });

        if (transitionResponse.data.transitionOrderToState.__typename === 'OrderStateTransitionError') {
          setShowError(true);
          return;
        }
      }

      initiatePaymentFlow(orderId, key_id);
    },
  });

  const [addPaymentToOrder] = useMutation(ADD_PAYMENT_TO_ORDER, {
    onError: (error) => {
      console.error('Add payment to order error:', error);
      setShowError(true);
    },
    onCompleted: async () => {
      try {
        await refetch(); // Fetch updated order details
        setShowModal(true);
      } catch (error) {
        console.error('Error fetching order:', error);
      }

      try {
        await refetchActiveOrder(); // Update active order to null
      } catch (error) {
        console.error('Error refetching active order:', error);
      }
    }
  });

  const handlePayment = async () => {
    setShowError(false);

    if (cartState.customFields.rzpayOrderId) {
      // If Razorpay order ID already exists, use it
      setRazorpayOrderId(cartState.customFields.rzpayOrderId);
      proceedToPayment(cartState.customFields.rzpayOrderId);
    } else if (
      (cartState.state === 'AddingItems' ||
      cartState.state === 'AvailabilityConfirmed') && !availabilityNeeded
    ) {
      // Create new Razorpay order if conditions are met
      await createRazorpayOrder();
    }
  };

  const proceedToPayment = async (orderId) => {
    if (cartState.state !== 'ArrangingPayment') {
      try {
        const response = await transitionOrderToState({
          variables: {
            state: 'ArrangingPayment',
          },
        });

        if (response.data.transitionOrderToState.__typename !== 'OrderStateTransitionError') {
          initiatePaymentFlow(orderId);
        }
      } catch (error) {
        console.error('Transition order error:', error);
        setShowError(true);
      }
    } else {
      initiatePaymentFlow(orderId);
    }
  };

  const initiatePaymentFlow = (orderId, key_id) => {
    const options = {
      key: key_id || process.env.REACT_APP_RAZORPAY_KEY, // Use provided key or environmental variable
      amount: cartState.totalWithTax.toString(),
      currency: cartState.currencyCode,
      name: "Wayanadfinder",
      description: "Payment",
      image: "https://wayanadfinder.in/logo_transparent.png",
      order_id: orderId,
      handler: async (response) => {
        try {
          const paymentInput = {
            method: 'razorpay',
            metadata: {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            },
          };

          await addPaymentToOrder({
            variables: {
              input: paymentInput,
            },
          });
        } catch (error) {
          console.error('Add payment error:', error);
          setShowError(true);
        }
      },
      prefill: {
        name: `${cartState.customer.firstname} ${cartState.customer.lastname}`,
        email: cartState.customer.emailAddress,
        contact: cartState.customer.phoneNumber
      },
      notes: {
        address: cartState.billingAddress.streetLine1,
        orderCode: cartState.code
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      alert(`Payment Failed: ${response.error.reason}`);
    });

    rzp1.open();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
  };

  const OrderSummary = ({ modalOrder, currencyCode }) => (
    <div>
      <h6>Order Code: {modalOrder.code}</h6>
      <p>Items: {modalOrder.totalQuantity}</p>
      <p><small>Subtotal: {formatCurrency(modalOrder.subTotalWithTax, currencyCode)}</small></p>
      { 
        <p><small>Shipping: {modalOrder.shippingWithTax?formatCurrency(modalOrder.shippingWithTax, currencyCode):0}</small></p>
      }
      <p><strong>Total: {formatCurrency(modalOrder.totalWithTax, currencyCode)}</strong></p>
    </div>
  );

  const PaymentSuccessModal = ({ showModal, onHideModal }) => (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="d-flex align-items-center">
            <BsCheckCircleFill className="me-2 text-success" /> Payment Successful
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          <Col xs={10} md={6} className="text-center mb-3">
            <Image src="images/order_confirmed.svg" rounded fluid className='mb-2'/>
            <p className='mt-2'>Your order has been successfully placed.</p>
          </Col>
          <Col xs={12} md={6}>
            {modalOrder ? (
              <OrderSummary modalOrder={modalOrder} currencyCode={modalOrder.currencyCode} />
            ) : (
              <Spinner animation="border" />
            )}
            <p className="text-muted">
              Please keep a note of the <strong>Order Code</strong> for future reference.
            </p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (getOrderLoading || razorpayLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <div>
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          Payment method not available. Try again later.
        </Alert>
      )}
      <Button
        id="rzp-button1"
        onClick={handlePayment}
        disabled={razorpayLoading || availabilityNeeded}
        variant="primary"
      >
        {razorpayLoading ? <Spinner animation="border" size="sm" /> : "Proceed to Payment"}
      </Button>

      <PaymentSuccessModal showModal={showModal} onHideModal={handleCloseModal}/>
        
    </div>
  );
};

export default PaymentSection;
