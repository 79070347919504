// ProductPage
import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { useMutation } from '@apollo/client';
import { ADD_ITEM_TO_ORDER } from '../../utils/queries';

import ProductImages from '../layouts/ProductImages';
import ProductTitle from '../layouts/ProductTitle';
import ProductVariantList from '../layouts/ProductVariantList';
import ProductDescripton from '../layouts/ProductDescripton';
import QuantityInput from '../QuantityInput';
import ProductAttributes from '../ProductAttributes';
import StayBookingForm from '../elements/StayBookingForm';
import { fetchProductDetails } from '../../utils/UtilFunctions';
import Seo from '../layouts/Seo';

const ProductPage = () => {
  const { slug } = useParams();
  const { dispatch } = useContext(CartContext);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [productAssets, setProductAssets] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      setIsLoading(true);
      try {
        const response = await fetchProductDetails(slug);
        setProductData(response);

        // Automatically select the single variant if only one exists
        if (response.variants.length === 1) {
          const singleVariant = response.variants[0];
          setSelectedVariant(singleVariant);
          setProductAssets(singleVariant.assets);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductData();
  }, [slug]);

  const handleVariantSelected = (variant) => {
    setSelectedVariant(variant);
    if (variant && variant.assets.length > 0) {
      setProductAssets(variant.assets);
    }
  };

  const handleSetQuantity = (selectedQuantity) => {
    setQuantity(selectedQuantity);
  };

  const [addItemToOrder] = useMutation(ADD_ITEM_TO_ORDER, {
    onCompleted: (data) => {
      const fetchedData = data?.addItemToOrder;
      if (fetchedData) {
        dispatch({
          type: "UPDATE_CART",
          payload: fetchedData
        });
      }
    },
    onError: (err) => {
      console.error('Error adding item to order:', err.message);
      // Handle error here, e.g., display error message to user
    },
  });
  
  const handleAddToCart = async () => {
    if (selectedVariant) {
      try {
        await addItemToOrder({
          variables: { variantId: selectedVariant.id, quantity: quantity }
        });
      } catch (error) {
        console.error('Error adding item to order:', error.message);
        // Handle error here, e.g., display error message to user
      }
    }
  };
  

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching products</div>;

  return (
    <>
    <Seo
      title ={`Wayanadfinder | ${productData?.name}`}
      description={`Looking for ${productData?.name}? The best set of Stays products and travel experience are available here. Wayanad trip or a location beyond Wayanad, we offer the best.`}
      type="webapp"
      imageUrl={`${productAssets.length?productAssets[0]?.preview:productData?.assets[0]?.preview}?preset=thumb`}
    />
    <Container>
      {productData && (
        <>
          <ProductTitle title={productData.name}></ProductTitle>
          <Row className='mb-2'>
            <Col sm={12} lg={6} className='mb-2'>
              <ProductImages assets={productAssets.length ? productAssets : productData?.assets}></ProductImages>
            </Col>
            <Col sm={12} lg={6}>
              <ProductVariantList variants={productData.variants} onVariantSelected={handleVariantSelected} selectedVariant={selectedVariant}></ProductVariantList>
              
              {selectedVariant && 
              <Row>
                <Col>
                  <ProductAttributes variant={selectedVariant}></ProductAttributes>
                  {
                    selectedVariant.customFields?.productType === "Accommodation" &&
                    <StayBookingForm 
                    setOrderQuantity={handleSetQuantity} 
                    variant={selectedVariant}
                    />
                  }
                </Col>
            </Row>
              }
              {
                (selectedVariant && selectedVariant.customFields?.productType !== "Accommodation") && 
                <>
                  <QuantityInput 
                  initialQuantity={quantity} 
                  setOrderQuantity={handleSetQuantity} 
                  availableStock={(selectedVariant && selectedVariant.customFields?.productType === "Accommodation") ? productData.customFields?.maxAvailable : productData.stockLevel === "IN_STOCK" ? 100 : 0}>
                  </QuantityInput>
                  <Button className='mt-2' onClick={handleAddToCart}>Add to Cart</Button>
                </>                
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <ProductDescripton description={productData.description}></ProductDescripton>
            </Col>
          </Row>
          {console.log("product data", productData)}
        </>
      )}
    </Container>
    </>
  );
}

export default ProductPage;
