import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { formatCurrency } from '../../utils/UtilFunctions';
import Pagination from 'react-bootstrap/Pagination';
import { GET_COLLECTION_PRODUCTS } from '../../utils/queries';
import Seo from '../layouts/Seo';

function ProductList() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const viewProduct = (slug) => {
    // Determine navigation based on current route
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/products')) {
      navigate(`/products/detail/${slug}`);
    } else if (currentPath.startsWith('/holidays/stays')) {
      navigate(`/stays/detail/${slug}`);
    }
  };

  const { loading, error, data, refetch } = useQuery(GET_COLLECTION_PRODUCTS, {
    variables: { slug, skip, take },
  });

  useEffect(() => {
    refetch(); // Trigger refetch when skip or take values change
  }, [skip, take, refetch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching products</div>;

  const handlePagination = (page) => {
    const newSkip = (page - 1) * take;
    setSkip(newSkip);
  };

  return (
    <>
    <Seo
      title ={`Wayanadfinder | ${slug}`}
      description={`Looking for ${slug}? The best set of Stays products and travel experience are available here. Wayanad trip or a location beyond Wayanad, we offer the best.`}
      type="webapp"
    />
    <Container>
      <h1>All {slug}</h1>
      <Row xs={1} md={4} className="g-4">
        {data.search.items?.map((product, index) => (
          <Col key={index}>
            <Card
              style={{ width: '18rem', borderRadius: '0', cursor: 'pointer' }}
              onClick={() => viewProduct(product.slug)}
            >
              <Card.Img variant="top" src={product.productAsset?.preview+"?preset=small"} style={{ borderRadius: '0' }} />
              <Card.Body>
                <Card.Title>{product.productName}</Card.Title>
                <Card.Text>
                  from {product.currencyCode}
                  <span className="fw-bold mx-1">
                    {formatCurrency(product.priceWithTax.min, product.currencyCode)}
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Pagination */}
      {data.search.totalItems > take && (
        <Pagination className="mt-3 justify-content-center">
          {Array.from({ length: Math.ceil(data.search.totalItems / take) }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={skip === i * take}
              onClick={() => handlePagination(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      )}
    </Container>
    </>
  );
}

export default ProductList;
