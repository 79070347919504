import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, Row, Col, FormControl } from "react-bootstrap";

const QuantityInput = ({ initialQuantity, setOrderQuantity , availableStock}) => {
  const [quantity, setQuantity] = useState(initialQuantity?initialQuantity:1);

  const handleIncrease = () => {
    const newQuantity = Math.min(quantity + 1, 10);
    setQuantity(newQuantity);
    setOrderQuantity(newQuantity);
  };

  const handleDecrease = () => {
    const newQuantity = Math.max(quantity - 1, 1);
    setQuantity(newQuantity);
    setOrderQuantity(newQuantity);
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (Number.isInteger(newQuantity) && newQuantity >= 1) {
      setQuantity(newQuantity);
      setOrderQuantity(newQuantity);
    }
  };

  return (
    <Row className="my-2 justify-content-start">
      <Col>
        <Form inline>
          <Row noGutters className="align-items-center">
            <Form.Text>Quantity</Form.Text>
            <Col xs="auto" className="pe-0">
              <Button
                size="sm"
                variant="secondary"
                onClick={handleDecrease}
                className="quantity-button-left"
              >
                -
              </Button>
            </Col>
            <Col xs={1} className="quantity-form" l>
              <FormControl
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                className="quantity-input"
                size="sm"
              />
            </Col>
            <Col xs="auto" className="ps-0">
              <Button
                size="sm"
                variant="secondary"
                onClick={handleIncrease}
                className="quantity-button-right"
              >
                +
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default QuantityInput;