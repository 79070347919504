import React, { useContext, useState } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { useMutation } from '@apollo/client';
import { REMOVE_ITEM_FROM_ORDER } from '../../utils/queries';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import {Image} from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import { Row,Col,Container } from 'react-bootstrap';
import { formatCurrency, getTaxTotalSum, getDiscountTotalSum, getNightCount } from '../../utils/UtilFunctions';
import { BsPencilSquare, BsTrash, BsThreeDotsVertical } from 'react-icons/bs';
import ClearCartWarning from '../modals/ClearCartWarning';
import EditQuantityModal from '../modals/EditQuantityModal';
import { useNavigate } from 'react-router-dom';
import Seo from '../layouts/Seo';

function CartPage() {
  const { cartState, dispatch } = useContext(CartContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clearCartModal, setClearCartModal] = useState(false)
  const [showEditModal,setShowEditmodel] = useState(false)

  const navigate = useNavigate();

  const [removeItemFromOrder] = useMutation(REMOVE_ITEM_FROM_ORDER, {
    onCompleted: (data) => {
        const updatedCart = data?.removeOrderLine;
        console.log("updatedcart after delete", updatedCart);
        dispatch({ type: 'UPDATE_CART', payload:updatedCart });
    },
    onError: (error) => {
      console.error('Error clearing cart:', error);
    },
  });

  const handleRowSelect = (item) => {
    setSelectedRow((prevSelectedRow) => (prevSelectedRow === item && !prevSelectedRow.isEditing ? null : item));
  };

  const handleEditItem = (item) => {
    setSelectedRow({ ...item, isEditing: true });
    setShowEditmodel(true);
  };

  const closeEditModal = ()=>{
    setShowEditmodel(false)
  }
  const handleDeleteItem = async () => {
    if(selectedRow){
        try{
            await removeItemFromOrder({
                variables:{orderLineId: selectedRow.id}
            })
    
        }
        catch(error){
            console.error('Error removing item from order:', error.message);
            // Handle error here, e.g., display error message to user
        }
        finally{
            setSelectedRow(null);
        }
    }
  };

  const handleClearCartConfirmation = () => {
    // if (window.confirm('Are you sure you want to clear your cart?')) {
    //   dispatch({ type: 'CLEAR_CART' });
    // }
    setClearCartModal(true);
  };
  const handleClearCartClose = ()=>{
    setClearCartModal(false)
  }

  const handleNavigateToCheckout = ()=>{
    if(cartState?.lines.length>0){
      navigate('/checkout');
    }
  }  
  
  return (
    <>
        <Seo title ="Wayanadfinder | Cart"
            description="Edit or proceed to checkout the best set of products and travel experience from us"
            type="webapp"
        />
        <Container>
            <Row>
                <Col>
                    <h2>Cart</h2>
                    <hr className='text-secondary'/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup className="mb-2" >
                        <Dropdown as={Button} variant="outline-secondary">
                        <Dropdown.Toggle variant="transparent">
                            <BsThreeDotsVertical />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleClearCartConfirmation()} disabled={cartState.state !== "AddingItems"}>
                            Clear Cart
                            </Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>

                        {selectedRow && !selectedRow.isEditing && (
                        <>
                            <Button onClick={() => handleEditItem(selectedRow)} variant="outline-primary" disabled={cartState.state !== "AddingItems"}>
                            <BsPencilSquare />
                            </Button>
                            <Button onClick={handleDeleteItem} variant="outline-danger" disabled={cartState.state !== "AddingItems"}>
                            <BsTrash />
                            </Button>
                        </>
                        )}
                    </ButtonGroup>
                    {cartState?.lines.length > 0 && (
                        <Table striped="columns" bordered hover responsive>
                        <thead>
                            <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price with Tax</th>
                            <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartState.lines.map((item, index) => {
                            return (
                                <tr
                                key={index}
                                onClick={() => handleRowSelect(item)}
                                className={selectedRow === item ? 'selected-row' : ''}
                                >
                                <td><span className='me-1'><Image className='cartImage' src={item?.featuredAsset?.preview+"?preset=tiny"} thumbnail/></span><span>{item.productVariant.name}</span></td>
                                <td>
                                    {item.customFields.bookingData?(`${item.quantity} x room for ${getNightCount(item.customFields.bookingData)} x nights`):item.quantity}
                                </td>
                                <td>{formatCurrency(item.unitPriceWithTax, cartState.currencyCode)}</td>
                                <td>{formatCurrency(item.linePriceWithTax, cartState.currencyCode)}</td>
                                </tr>
                            );
                            })}
                        </tbody>
                        </Table>
                    )}
                    {cartState?.lines.length === 0 && (
                        <p className="ms-2 text-danger">Your Cart is Empty</p>
                    )}
                </Col>
            </Row>
            {cartState?.discounts && <Row className='text-end'>
                <Col>
                    {cartState?.discounts.length >0 && <h6>Discounts</h6>}
                </Col>
                <Col>
                    {cartState?.discounts.length >0 && <><span className='me-2'>{cartState?.currencyCode}</span><span>{formatCurrency(getDiscountTotalSum(cartState?.discounts),cartState?.currencyCode)}</span></>}
                </Col>
            </Row>}
            <Row className='text-end'>
                <Col>
                    <h6>Tax included</h6>
                </Col>
                <Col>
                    {cartState?.lines.length > 0 && <><span className='me-2'>{cartState?.currencyCode}</span><span>{formatCurrency(getTaxTotalSum(cartState?.taxSummary),cartState?.currencyCode)}</span></>}
                </Col>
            </Row>
            <Row className='text-end'>
                <Col>
                    <h4>Subtotal</h4>
                </Col>
                <Col>
                    {cartState?.lines.length > 0 && <h4><span className='me-2'>{cartState?.currencyCode}</span>{formatCurrency(cartState?.totalWithTax,cartState?.currencyCode)}</h4>}
                </Col>
            </Row>
            <Row >
                <Col >
                    <Button variant="primary" onClick={()=>handleNavigateToCheckout()}>Checkout</Button>
                </Col>
            </Row>
            {clearCartModal && <ClearCartWarning showModal={clearCartModal} closeModal={handleClearCartClose}></ClearCartWarning>}
            {showEditModal && <EditQuantityModal showModal={showEditModal} selectedRow={selectedRow} handleRowSelect={handleRowSelect} closeModal={closeEditModal}/>}
        </Container>
        </>
    
  );
}

export default CartPage;
