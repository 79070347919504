import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import DOMPurify from 'dompurify'

function ProductDescription({ description }) {
  // Sanitize the HTML content to avoid XSS attacks
  const sanitizedDescription = DOMPurify.sanitize(description);

  return (
    <Container className='my-3'>
        <Row>
            <h2>Description</h2>
        </Row>
        <Row>
            <Col>
                <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
            </Col>
        </Row>
    </Container>
  )
}

export default ProductDescription