import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useMutation } from '@apollo/client';
import { AUTHENTICATE, GET_ACTIVE_ORDER } from '../../utils/queries';

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const { currentUser, register, setError, updateUserProfile } = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [authenticateUser] = useMutation(AUTHENTICATE);

    useEffect(() => {
        if (currentUser) {
            authenticateWithVendure();
        }
    }, [currentUser, navigate]);

    const authenticateWithVendure = async () => {
        try {
            console.log("inside if loop to call authenticate mutation");
            // Authenticate with Vendure upon successful registration
            const { data } = await authenticateUser({
                variables: {
                    token: currentUser.accessToken, // Pass Firebase token to Vendure
                },
                refetchQueries:[{
                    query: GET_ACTIVE_ORDER
                }]
            });

            if (data && data.authenticate.__typename === 'CurrentUser') {
                // Navigate user to profile or checkout based on redirect query param
                if (searchParams && searchParams.get('redirect') === 'checkout') {
                    navigate('/checkout');
                } else {
                    navigate('/profile');
                }
            } else {
                setError('Failed to authenticate with Vendure');
            }
        } catch (error) {
            setError('Failed to authenticate with Vendure');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!firstName || !lastName) {
            return setError("Please provide your first name and last name");
        }

        if (password !== confirmPassword) {
            return setError("Passwords do not match");
        }

        if (!e.target.formBasicCheckbox.checked) {
            return setError("Please accept the Privacy Policy and Terms of Use");
        }

        try {
            setError("");
            setLoading(true);
            const userCredential = await register(email, password);
            
            // Check if userCredential.user exists and if so, update the profile
            if (userCredential && userCredential.user) {
                await updateUserProfile(userCredential.user, { displayName: "info test" }); // Update the display name here
            } else {
                throw new Error("Failed to register user or user data not available");
            }
        } catch (error) {
            setError(error.message);
        }

        setLoading(false);
    };

    return (
        <Container>
            <Row className="m-2 justify-content-center align-items-center">
                <Col className="m-2 justify-content-center align-items-center" lg="6">
                    <h2 className="text-center">Register your account</h2>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                                placeholder="Enter first name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                                placeholder="Enter last name"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                placeholder="Enter email"
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formRepeatPassword">
                            <Form.Label>Repeat Password</Form.Label>
                            <Form.Control
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type="password"
                                placeholder="Repeat Password"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label={<span>I accept the <Link to="/privacy-policy">Privacy Policy</Link> and <Link to="/terms-of-use">Terms of Use</Link></span>} />
                        </Form.Group>
                        <Button disabled={loading} variant="primary" type="submit">
                            Register
                        </Button>
                    </Form>
                    <div className="m-3 text-center">
                        <p>
                            Already have an account? <Link className="mx-2" to="/login">Sign in</Link>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
