import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import CouponCode from '../elements/CouponCode';
import { CartContext } from '../../contexts/CartContext';
import { Link } from 'react-router-dom';
import Addresses from '../elements/Addresses';
import ShippingMethods from '../elements/ShippingMethods';
import { fetchCartProductTypes, checkIfPhysicalProductsInCart } from '../../utils/cartService'; 
import { client } from '../../utils/apolloClient'; 
import { useMutation } from '@apollo/client';
import { TRANSITION_ORDER_STATE, SET_SHIPPING_METHOD } from '../../utils/queries';
import PaymentSection from '../elements/PaymentSection';

const CheckoutPage = () => {
  const [currentStep, setCurrentStep] = useState("0");
  const { cartState } = useContext(CartContext);
  const [availabilityNeeded, setAvailabilityNeeded] = useState(false);
  const [availabilityChecked, setAvailabilityChecked] = useState(false);
  const [availabilityMessage, setAvailabilityMessage] = useState("");
  const [hasPhysicalProducts, setHasPhysicalProducts] = useState(false);
  const [setShippingMethod, { loading: mutationLoading, error: mutationError }] = useMutation(SET_SHIPPING_METHOD);

  useEffect(() => {
    handleCurrentStep();
    checkPhysicalProducts();
    if(cartState.state === "AddingItems"){
      checkProductTypes();
    }
  }, [cartState]);

  const handleCurrentStep = () => {
    if (cartState.shippingAddress?.streetLine1 && cartState.billingAddress?.streetLine1) {
      if (cartState.shippingLines.length > 0) {
        if(cartState.state === "AvailabilityConfirmed"){
          setCurrentStep("3")
        }
        else{
          setCurrentStep("2")
        }
      } else {
        if(hasPhysicalProducts){
          setCurrentStep("1");
        }
        else{
          if(cartState.state === "AvailabilityConfirmed"){
            setCurrentStep("3")
          }
          else{
            setCurrentStep("2")
          }
        }
      }
    }else{
      if(!hasPhysicalProducts && cartState.billingAddress?.streetLine1){
        if(cartState.state === "AvailabilityConfirmed"){
          setCurrentStep("3")
        }
        else{
          setCurrentStep("2")
        }
      }
      else{
        setCurrentStep("0")
      }
    }
  };

  const checkProductTypes = async () => {
    const needsAvailability = await fetchCartProductTypes(cartState.lines);
    setAvailabilityNeeded(needsAvailability);
    await setShippingMethod({
      variables: { id: 3 },
    });
  };

  const checkPhysicalProducts = async () => {
    const hasPhysical = await checkIfPhysicalProductsInCart(cartState.lines);
    setHasPhysicalProducts(hasPhysical);
  };

  const handleCheckAvailability = async () => {
    try {
      const { data } = await client.mutate({
        mutation: TRANSITION_ORDER_STATE,
        variables: { state: "AvailabilityPending" }
      });

      if (data.transitionOrderToState.__typename === "OrderStateTransitionError") {
        setAvailabilityMessage(`Error: ${data.transitionOrderToState.message}`);
      } else {
        setAvailabilityChecked(true);
        setAvailabilityMessage("We will notify you by email when the availability is confirmed. Keep checking for an email from us.");
      }
    } catch (error) {
      console.error('Error transitioning order state:', error);
      setAvailabilityMessage("There was an issue checking availability. Please try again.");
    }
  };

  return (
    <Container>
      {cartState.customer ? (
        <>
          <Row>
            <Col>
              <h2>Checkout</h2>
              <hr className="text-secondary" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail" lg={6} xs={12}>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder={cartState.customer.emailAddress} disabled />
            </Form.Group>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <CouponCode />
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion activeKey={currentStep} onSelect={(eventKey) => setCurrentStep(eventKey)}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Addresses</Accordion.Header>
                  <Accordion.Body>
                    <Addresses hasPhysical={hasPhysicalProducts}/>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Shipping methods
                  </Accordion.Header>
                  <Accordion.Body>
                  {hasPhysicalProducts ?<ShippingMethods hasPhysicalProducts={hasPhysicalProducts}/>: <p>No physicals products in cart proceed to next step</p>}
                  </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Check availability
                  </Accordion.Header>
                  <Accordion.Body>
                    {availabilityNeeded ? (
                      <>
                        <Alert variant="info">Some items in your cart need availability confirmation before proceeding further.</Alert>
                        <Button onClick={handleCheckAvailability} disabled={availabilityChecked}>
                          {availabilityChecked ? "Availability Checked" : "Check Availability"}
                        </Button>
                        {availabilityMessage && <p>{availabilityMessage}</p>}
                      </>
                    ) : ( cartState.state === "AvailabilityPending"?
                    <Alert variant="info">Waiting for Availability confirmation. We will notify you once availability is confirmed. Keep checking your email.</Alert>
                      : cartState.state==="AvailabilityConfirmed"?<p>Availability Confirmed! Please proceed to next step.</p>:<p>Availability confirmation not required. Please proceed to next step</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Payment</Accordion.Header>
                  <Accordion.Body>
                    <PaymentSection availabilityNeeded={availabilityNeeded}/>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <h3>Login</h3>
            <p>
              Returning user? <Link to="/login?redirect=checkout">login</Link>/ New here?{' '}
              <Link to="/register?redirect=checkout">Register</Link> to avail all features like view your orders.
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CheckoutPage;
