// utils/cartService.js

import { client } from './apolloClient'; // Ensure you import your configured Apollo Client
import { FETCH_PRODUCT_TYPE_BYID } from './queries';

export const fetchCartProductTypes = async (cartLines) => {
  const productIds = [...new Set(cartLines.map(line => line.productVariant.productId))]; // Get unique product IDs from cart lines

  const productDetailsPromises = productIds.map(async (productId) => {
    const { data } = await client.query({
      query: FETCH_PRODUCT_TYPE_BYID,
      variables: { id: productId }
    });
    return data.product;
  });

  const productDetails = await Promise.all(productDetailsPromises);

  // Extract all product types from the product details
  const productTypes = productDetails.flatMap(product =>
    product.variantList.items.map(variant => variant.customFields.productType)
  );

  // Check if any product type is 'Bookable' or 'Accommodation'
  return productTypes.some(type => ['Bookable', 'Accommodation'].includes(type));
};


export const checkIfPhysicalProductsInCart = async (cartLines) => {
  const productIds = [...new Set(cartLines.map(line => line.productVariant.productId))]; // Get unique product IDs from cart lines

  const productDetailsPromises = productIds.map(async (productId) => {
    const { data } = await client.query({
      query: FETCH_PRODUCT_TYPE_BYID,
      variables: { id: productId }
    });
    return data.product;
  });

  const productDetails = await Promise.all(productDetailsPromises);

  // Check if any product variant has the type 'Digital'
  return productDetails.some(product =>
    product.variantList.items.some(variant => variant.customFields.productType === "Physical")
  );
};
