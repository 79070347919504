import React from 'react';
import { PlasmicComponent } from '@plasmicapp/loader-react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <Container>
        <Row className='mt-4'>
        <span className="border-top"></span>
            <Col className='mt-4'>
                <PlasmicComponent component="footer-new" />
            </Col>
        </Row>
    </Container>
  )
}

export default Footer