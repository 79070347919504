import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_CUSTOMER } from '../../utils/queries';
import { Container, Row, Col, Card, Tabs, Tab, Button, Dropdown } from 'react-bootstrap';

function Profile() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState('profile');
  const { currentUser, updateUserProfile, setError } = useAuth();
  const [pagination, setPagination] = useState({ skip: 0, take: 10 });
  const [sortOrder, setSortOrder] = useState('updatedAt');

  const { data, loading: queryLoading, error, refetch } = useQuery(GET_ACTIVE_CUSTOMER, {
    variables: { skip: pagination.skip, take: pagination.take },
    skip: !currentUser, // Skip query if no currentUser
  });

  useEffect(() => {
    if (currentUser) {
      refetch(); // Refetch data whenever the current user changes
      setPagination({ skip: 0, take: 10 }); // Reset pagination
    }
  }, [currentUser, refetch]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (username.length === 0) {
      return setError("Please input a username");
    }
    try {
      setError("");
      setLoading(true);
      const user = currentUser;
      const profile = { displayName: username };
      await updateUserProfile(user, profile);
      navigate("/");
    } catch (e) {
      setError("Failed to update profile");
    }
    setLoading(false);
  };

  const sortedOrders = useMemo(() => {
    if (!data || !data.activeCustomer || !data.activeCustomer.orders) return [];
    const orders = [...data.activeCustomer.orders.items];
    return orders.sort((a, b) => new Date(b[sortOrder]) - new Date(a[sortOrder]));
  }, [data, sortOrder]);

  if (queryLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const { activeCustomer } = data;

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col lg={6}>
          {/* <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Set Username</Form.Label>
              <Form.Control type="text" placeholder={currentUser.displayName || "Enter a username"}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Button disabled={loading} variant="primary" type="submit">
              Submit
            </Button>
          </Form> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
             <Tab eventKey="profile" title="Profile">
              <Row>
                <Col>
                  <Card className="text-center">
                    <Card.Header>Welcome to wayanadfinder!</Card.Header>
                    <Card.Body>
                      <Card.Title>Hello {currentUser.displayName ? currentUser.displayName : "Please sign in"}</Card.Title>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                      <p>ID: {activeCustomer.id} | Name: {activeCustomer.firstName} {activeCustomer.lastName} | Email: {activeCustomer.emailAddress}</p>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="orders" title="Orders">
              <div className="d-flex justify-content-end mb-3">
                <Dropdown size="sm">
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Sort by: {sortOrder}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSortOrder('createdAt')}>Created At</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortOrder('updatedAt')}>Updated At</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                {sortedOrders.map(order => (
                  <Card key={order.code} className="mb-2">
                    <Card.Body>
                      <Card.Title>{order.state !== "AddingItems"?order.code: "Items are still in the cart"}</Card.Title>
                      <Card.Text>
                        State: {order.state}<br />
                        Total: {order.currencyCode} {order.totalWithTax / 100}<br />
                        Quantity: {order.totalQuantity}<br />
                        Created At: {new Date(order.createdAt).toLocaleString()}<br />
                        Updated At: {new Date(order.updatedAt).toLocaleString()}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
                <div className="d-flex justify-content-between">
                  <Button variant="link" onClick={() => setPagination({ ...pagination, skip: pagination.skip - 10 })} disabled={pagination.skip === 0}>Previous</Button>
                  <Button variant="link" onClick={() => setPagination({ ...pagination, skip: pagination.skip + 10 })} disabled={sortedOrders.length < 10}>Next</Button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="addresses" title="Addresses">
              <div>
                {activeCustomer.addresses.map((address, index) => (
                  <Card key={index} className="mb-2">
                    <Card.Body>
                      <Card.Title>{address.fullName}</Card.Title>
                      <Card.Text>
                        {address.streetLine1} {address.streetLine2}<br />
                        {address.city}, {address.province} {address.postalCode}<br />
                        {address.country.name}<br />
                        Phone: {address.phoneNumber}<br />
                        Default Billing: {address.defaultBillingAddress ? 'Yes' : 'No'}<br />
                        Default Shipping: {address.defaultShippingAddress ? 'Yes' : 'No'}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
