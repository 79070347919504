import { gql } from "@apollo/client";

export const ACTIVE_ORDER_FRAGMENT = gql`
  fragment ActiveOrder on Order {
    __typename
    id
    code
    couponCodes
    state
    currencyCode
    totalQuantity
    subTotalWithTax
    shippingWithTax
    totalWithTax
    discounts {
      description
      amountWithTax
    }
    taxSummary{
      taxBase
      taxRate
      taxTotal
    }
    customFields{
      rzpayOrderId
    }
    lines {
      id
      unitPriceWithTax
      quantity
      linePriceWithTax
      customFields{
        bookingData
      }
      productVariant {
        id
        productId
        name
        sku
      }
      featuredAsset {
        id
        preview
      }
    }
    shippingLines {
      shippingMethod {
        name
        description
      }
      priceWithTax
    }
    customer{
      id
      firstName
      lastName
      phoneNumber
      emailAddress
    }
    shippingAddress{
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country
      countryCode
      phoneNumber
      customFields
    }
    billingAddress{
      fullName
      company
      streetLine1
      streetLine2
      city
      province
      postalCode
      country
      countryCode
      phoneNumber
      customFields
    }
  }
`

