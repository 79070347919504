// Addresses.js

import React, { useState, useEffect, useContext } from 'react';
import { CartContext } from '../../contexts/CartContext';
import AddressForm from './AddressForm';
import SelectAddress from './SelectAddress';
import { Row, Col, Form } from 'react-bootstrap';
import { GET_CUSTOMER_ADDRESSES } from '../../utils/queries';
import { useQuery, useMutation } from '@apollo/client';
import { SET_ORDER_SHIPPING_ADDRESS, SET_ORDER_BILLING_ADDRESS } from '../../utils/queries';

const Addresses = ({ hasPhysical }) => {
  const { cartState, dispatch } = useContext(CartContext);
  const [shippingAddress, setShippingAddress] = useState(cartState?.shippingAddress || {});
  const [billingAddress, setBillingAddress] = useState(cartState?.billingAddress || {});
  const [sameBillingAddress, setSameBillingAddress] = useState(true);

  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_ADDRESSES);
  const [setOrderShippingAddress] = useMutation(SET_ORDER_SHIPPING_ADDRESS);
  const [setOrderBillingAddress] = useMutation(SET_ORDER_BILLING_ADDRESS);

  useEffect(() => {
    refetch();
    if (cartState.billingAddress) {
      setBillingAddress(cartState.billingAddress);
    }
    if (cartState.shippingAddress) {
      setShippingAddress(cartState.shippingAddress);
    }
  }, [cartState, refetch]);

  useEffect(() => {
    if (!hasPhysical) {
      setSameBillingAddress(false); // Ensure billing address is shown if no physical products
    }
  }, [hasPhysical]);

  // Function to handle form submission
  const handleSubmit = async (formData, type) => {
    if (type === "shipping") {
      try {
        // Set shipping address
        await setOrderShippingAddress({ variables: { input: formData } });
        setShippingAddress(formData);

        // If the billing address is the same, set it as well
        if (sameBillingAddress) {
          await setOrderBillingAddress({ variables: { input: formData } });
          setBillingAddress(formData);
        }

        // Update cart state
        dispatch({
          type: 'UPDATE_ADDRESSES',
          payload: {
            shippingAddress: formData,
            billingAddress: sameBillingAddress ? formData : billingAddress
          }
        });

      } catch (error) {
        console.error('Error setting shipping address:', error);
      }
    } else if (type === "billing") {
      try {
        // Set billing address
        await setOrderBillingAddress({ variables: { input: formData } });
        setBillingAddress(formData);

        // Update cart state
        dispatch({
          type: 'UPDATE_ADDRESSES',
          payload: {
            billingAddress: formData,
            shippingAddress
          }
        });

      } catch (error) {
        console.error('Error setting billing address:', error);
      }
    }
  };

  // Function to handle selecting address from dropdown
  const handleSelectAddress = (type, addressId) => {
    const addressSelected = data.activeCustomer.addresses.find(address => address.id === addressId);
    if (addressSelected) {
      if (type === "billing") {
        setBillingAddress(addressSelected);
      } else if (type === "shipping") {
        setShippingAddress(addressSelected);
        if (sameBillingAddress) {
          setBillingAddress(addressSelected);
        }
      }
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setSameBillingAddress(!sameBillingAddress);
    if (!sameBillingAddress) {
      // If switching to the same billing address, update billing address to match shipping address
      setBillingAddress(shippingAddress);
    }
  };

  return (
    <Row className='my-2'>
      {hasPhysical && (
        <Col lg={6} s={12}>
          <h3>Shipping Address</h3>
          {data && <SelectAddress addresses={data.activeCustomer.addresses} onSelect={(type, id) => handleSelectAddress(type, id)} type="shipping" />}
          <AddressForm type="shipping" formData={shippingAddress} onSubmit={handleSubmit} />
          <Form.Group className="my-3" id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              checked={sameBillingAddress}
              onChange={handleCheckboxChange}
              label="My billing address is the same"
            />
          </Form.Group>
        </Col>
      )}
      <Col lg={6} s={12}>
        {!sameBillingAddress && (
          <>
            <h3>Billing Address</h3>
            {data && <SelectAddress addresses={data.activeCustomer.addresses} onSelect={(type, id) => handleSelectAddress(type, id)} type="billing" />}
            <AddressForm type="billing" formData={billingAddress} onSubmit={handleSubmit} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default Addresses;
