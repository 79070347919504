import { ApolloClient, InMemoryCache,ApolloLink,
    HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Read API URL from environment variable
const API_URL = process.env.REACT_APP_ECOM_GRAPHQL_SERVER || 'http://localhost:3000/shop-api';
  
// Bearer token key for localStorage
const AUTH_TOKEN_KEY = 'vendure_auth_token';

const httpLink = new HttpLink({
  uri: API_URL,
  credentials: 'include', // Required for cookie-based session management (if applicable)
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const authHeader = context.response.headers.get('vendure-auth-token');
    if (authHeader) {
      // Store bearer token in localStorage
      storeAuthToken(authHeader);
    }
    return response;
  });
});

const storeAuthToken = (token) => {
  try {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  } catch (error) {
    console.error('Error storing auth token:', error);
  }
};

const authLink = setContext((request, { headers }) => {
  // Retrieve token from localStorage
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  // Return headers with Authorization header if token exists
  return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, afterwareLink, httpLink]),
  cache: new InMemoryCache(),
});