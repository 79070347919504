import React from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { CartContext } from '../../contexts/CartContext';
import { useMutation } from '@apollo/client';
import { LOGOUT, GET_ACTIVE_ORDER } from '../../utils/queries';

function Logout({ show, handleClose }) {

  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();
  const { logout: firebaseLogout, setError } = useAuth();
  const [logoutVendure] = useMutation(LOGOUT);
  const { dispatch } = useContext(CartContext);

  async function handleLogout() {
    try {
      // Logout user from Firebase
      await firebaseLogout();

      // Logout user from Vendure backend
      await logoutVendure({
          refetchQueries:[{
              query: GET_ACTIVE_ORDER
          }]
      });
      dispatch({type:"CLEAR_CART"});
      handleClose();
      navigate("/login");
    } catch (error) {
      setError("Failed to logout");
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Log out</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to log out?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button ref={cancelButtonRef} onClick={handleClose} variant="secondary">Cancel</Button>
        <Button onClick={handleLogout} variant="danger">Logout</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Logout;
