import React, { useState, useEffect,useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_SHIPPING_METHODS, SET_SHIPPING_METHOD } from '../../utils/queries'
import { CartContext } from '../../contexts/CartContext'
import { Row, Col, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';

const ShippingMethods = ({hasPhysicalProducts}) => {
    const {cartState} = useContext(CartContext);
    const [prevSelectedMethod, setPrevSelectedMethod] = useState(null);
    const [selectedMethodId, setSelectedMethodId] = useState(null); // Track selected method
    const { loading, error, data } = useQuery(GET_SHIPPING_METHODS);
    const [setShippingMethod, { loading: mutationLoading, error: mutationError }] = useMutation(SET_SHIPPING_METHOD);
    const [showDigitalShipping, setShowDigitalShipping] = useState(!hasPhysicalProducts)

    useEffect(() => {
        // Check for shipping method in cart state
        const selectedMethod = cartState?.shippingLines?.[0];
        if (selectedMethod) {
            setPrevSelectedMethod(selectedMethod);
          const selectedPrice = parseFloat(selectedMethod.priceWithTax); // Convert to number
    
          // Find matching method by price
          const matchingMethod = data?.eligibleShippingMethods.find(
            (method) => parseFloat(method.priceWithTax) === selectedPrice
          );
    
          if (matchingMethod) {
            setSelectedMethodId(matchingMethod.id);
          }
        }
      }, [cartState, data]);

    const handleRadioChange = (event) => {
        const methodId = event.target.id;
        setSelectedMethodId(methodId);
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!selectedMethodId) {
          return; // Prevent submission without a selection
        }
    
        try {
          await setShippingMethod({
            variables: { id: selectedMethodId },
          });
          // Handle successful mutation (e.g., display success message or redirect)
        } catch (error) {
          // Handle mutation error (e.g., display error message)
        }
      };

    if(loading){
        return (
            <Row>
                <Col>
                    <p>Loading..</p>
                </Col>
            </Row>
        )
    }
    if(error){
        console.error(error)
    }
    if(data){
        return(
            <Row>
                <Col>
                <Form onSubmit={handleSubmit}>
                <Form.Label>Select shipping Method</Form.Label>
                    {
                      data.eligibleShippingMethods.filter((method) =>
                        hasPhysicalProducts ? method.id !== "3" : true
                      ).map((method) => (
                        <Form.Check
                          key={method.id}
                          name="shippingMethod"
                          type="radio"
                          id={method.id}
                          label={`${method.name}: ${method.price}`}
                          value={method.id} // Add value for radio button
                          onChange={handleRadioChange} // Add event handler
                          checked={selectedMethodId ? selectedMethodId === method.id : parseFloat(method.price) === prevSelectedMethod?.priceWithTax}
                        />
                      ))
                    }
                    <Button type="submit" disabled={mutationLoading}>
                        {mutationLoading ? 'Saving...' : 'Save Shipping Method'}
                    </Button>
                </Form>  
                </Col>
            </Row>
        )
    }  
}

export default ShippingMethods
