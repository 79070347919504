import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { CartContext } from '../../contexts/CartContext';
import { useMutation } from '@apollo/client';
import { AUTHENTICATE, GET_ACTIVE_ORDER } from '../../utils/queries';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, setError, currentUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [authenticateUser] = useMutation(AUTHENTICATE);

    useEffect(() => {
        if (currentUser) {
          authenticateWithVendure();
        }
      }, [currentUser, navigate]);

          
    const authenticateWithVendure = async () => {
        try {
            console.log("inside if loop to call authenticate mutation");
            // Authenticate with Vendure upon successful login
            const { data } = await authenticateUser({
                variables: {
                    token: currentUser.accessToken, // Pass Firebase token to Vendure
                },
                refetchQueries: [{
                    query: GET_ACTIVE_ORDER
                }]
            });

            if (data && data.authenticate.__typename === 'CurrentUser') {
                // Navigate user to profile or checkout based on redirect query param
                if (searchParams && searchParams.get('redirect') === 'checkout') {
                    navigate('/checkout');
                } else {
                    navigate('/profile');
                }
            } else {
                setError('Failed to authenticate with Vendure');
            }
        } catch (error) {
            setError('Failed to authenticate with Vendure');
        }
    };

    async function handleFormSubmit(e) {
        e.preventDefault();
        try {
            setError('');
            setLoading(true);
            await login(email, password);
        } catch (error) {
            setError('Failed to login');
        }
        setLoading(false);
    }

    return (
        <Container>
            <Row className="m-2 justify-content-center align-items-center">
                <Col className="m-2 justify-content-center align-items-center" lg="6">
                    <h2 className="text-center">Login to your account</h2>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                placeholder="Enter email"
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Group>
                        <Button disabled={loading} variant="primary" type="submit">
                            Login
                        </Button>
                    </Form>
                    <div className="m-3 text-center">
                        <p>
                            Do not have an account? <Link className="mx-2" to="/register">Register</Link>
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
