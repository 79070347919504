import React from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Container, Card } from 'react-bootstrap';
import { GET_TOP_COLLECTIONS } from '../../utils/queries';
import Seo from '../layouts/Seo';

/**
 * 
 * @returns Component to list top level collections
 */
function Collections() {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const topLevelOnly = true;
    let filter = { slug: { notContains: "stays" } };

    // Handle different URL scenarios
    if (location.pathname === '/holidays/stays') {
    const locationParam = queryParams.get('location');
    if (locationParam) {
        if (locationParam === 'wayanad') {
        filter = { slug: { eq: `${locationParam}-stays` } };
        } else if (locationParam === 'beyond') {
        filter = { slug: { contains: "stays" } };
        }
    }
    } else if (location.pathname === '/products') {
    filter = { slug: { notContains: "stays" } };
    }

    const { loading, error, data } = useQuery(GET_TOP_COLLECTIONS, {
    variables: { topLevelOnly, filter },
    });

    const viewCollection = (target) => {
        navigate(target);
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;
    if (data) {
        return (
          <>
          <Seo
          title ="Wayanadfinder | Top categories"
          description="Book the best set of Stays products and travel experience from us. Wayanad trip or a location beyond Wayanad, we offer the best."
          type="webapp"
          />

          <Container>
            <h1>Top categories</h1>
            <Row xs={1} md={4} className="g-4">
              {data.collections.items.map((item) => (
                <Col key={item.id}>
                  <Card
                    style={{ width: '18rem', borderRadius: '0', cursor: 'pointer' }}
                    onClick={() => viewCollection(item.slug)}
                  >
                    <Card.Img variant="top" src={item.featuredAsset?.preview+"?preset=small"} style={{ borderRadius: '0' }} />
                    <Card.Body>
                      <Card.Title>{item.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
          </>
        );
    }
}

export default Collections