import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Row,Col,Alert} from 'react-bootstrap';
import { BsFillXCircleFill } from "react-icons/bs";
import { useContext, useState } from 'react'
import { CartContext } from '../../contexts/CartContext'
import { APPLY_COUPON_CODE, REMOVE_COUPON_CODE } from '../../utils/queries';
import { useMutation } from '@apollo/client';

const CouponCode = () => {
    const {cartState, dispatch} = useContext(CartContext);
    const [couponCode, setCouponCode] = useState("");

    const [applyCouponCode] = useMutation(APPLY_COUPON_CODE, {
        onCompleted: (data) => {
          const fetchedData = data?.applyCouponCode;
          if (fetchedData) {
            dispatch({
              type: "UPDATE_CART",
              payload: fetchedData
            });
          }
        },
        onError: (err) => {
          console.error('Error applying coupon to order:', err.message);
          // Handle error here, e.g., display error message to user
        },
      });

    const handleApplyCoupon = async ()=>{
        if (couponCode) {
            try {
              await applyCouponCode({
                variables: { couponCode: couponCode}
              });
            } catch (error) {
              console.error('Error adding item to order:', error.message);
              // Handle error here, e.g., display error message to user
            }
          }
    }

    const [removeCouponcode] = useMutation(REMOVE_COUPON_CODE, {
        onCompleted: (data) => {
          const fetchedData = data?.applyCouponCode;
          if (fetchedData) {
            dispatch({
              type: "UPDATE_CART",
              payload: fetchedData
            });
          }
        },
        onError: (err) => {
          console.error('Error applying coupon to order:', err.message);
          // Handle error here, e.g., display error message to user
        },
      });

    const handleRemoveCoupon = async (code)=>{
        if (code) {
            try {
              await removeCouponcode({
                variables: { couponCode: code}
              });
            } catch (error) {
              console.error('Error adding item to order:', error.message);
              // Handle error here, e.g., display error message to user
            }
          }
    }

        if (cartState.couponCodes.length > 0) {
          // List existing coupon codes
          return (
            <Row>
              <Col>
                <Alert variant="success">
                  <span>Coupon Applied</span>
                  <ul>
                    {cartState.couponCodes.map((code, index) => (
                      <li key={index}>{code}<span className='ms-2'><BsFillXCircleFill onClick={()=>{handleRemoveCoupon(code)}}/></span></li>
                    ))}
                  </ul>
                </Alert>
              </Col>
            </Row>
          );    
        } else {
          // Show CouponCode component for adding new coupons
          return (
            <Row>
                <Col>
                    <Row>
                        <h6>Have a coupon?</h6>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">            
                                <Form.Control
                                onChange={(e)=>{setCouponCode(e.target.value)}}
                                aria-label="Example text with button addon"
                                aria-describedby="basic-addon1"
                                />
                                <Button onClick={()=>handleApplyCoupon()} variant="outline-secondary" id="button-addon1">
                                Apply coupon
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
          )
        }
}

export default CouponCode;